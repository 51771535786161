import { useContext, memo, useEffect, Dispatch, SetStateAction, useState } from 'react';
import { Button, Input, message, Radio, Select } from 'antd';
import { INewTradeReq, Market, IErrorProps, OpenTrade } from '@powertrader/schema';
import styles from './TradingRequestForm.module.css';
import { gameProgressContext, openTradesCompletedContext, setErrorContext, setupDataContext } from '../../../context';
import LastTradedValues from './LastTradedValues';
import { socket } from '../../../socket/socket';
import { QuickLoginQR } from './QuickLoginQR';
import { tradeWeightedAverage } from '../../../calculations/subCalcs/tradeWeightedAverage';
import { isInteger, round } from 'lodash';

interface ITradeRequestForm {
  tradeType: OpenTrade['tradeType'];
  setTradeType: (R: OpenTrade['tradeType']) => void;
  teamTradesOnly: boolean;
  setTeamTradesOnly: Dispatch<SetStateAction<boolean>>;
  activeMarkets: Market[];
  newTradeReq: INewTradeReq;
  setNewTradeReq: React.Dispatch<React.SetStateAction<INewTradeReq>>;
}

const { Option } = Select;
export const TradeRequestForm = memo(
  ({ setTeamTradesOnly, teamTradesOnly, activeMarkets, setTradeType, tradeType, newTradeReq, setNewTradeReq }: ITradeRequestForm) => {
    const { roundID } = useContext(gameProgressContext);
    const { user, teams, settings } = useContext(setupDataContext);
    const completedTrades = useContext(openTradesCompletedContext);

    const { currency, minTradeAllowed, maxTradeAllowed, currentGamePhase } = settings;

    const setError = useContext(setErrorContext);

    const [receivingTeamID, setReceivingTeamID] = useState(user.teamID === 1 ? 2 : 1);

    useEffect(() => {
      setNewTradeReq(prevState => ({ ...prevState, tradeType }));
    }, [setNewTradeReq, tradeType]);

    useEffect(() => {
      if (!settings.allowCarbonTrading) {
        setTradeType('powerExchange');

        setNewTradeReq(prevState => ({ ...prevState, marketID: 1 }));
      }
    }, [setNewTradeReq, setTradeType, settings.allowCarbonTrading]);

    const submitTrade = () => {
      if (
        (+newTradeReq.price > 0 || +newTradeReq.price < 0) &&
        +newTradeReq.price > minTradeAllowed &&
        +newTradeReq.price < maxTradeAllowed &&
        (newTradeReq.tradeType !== 'carbonMarket' || +newTradeReq.price > 0)
      ) {
        const newTradeReqSubmission: INewTradeReq = {
          ...newTradeReq,
          startRoundID: roundID,
          endRoundID: roundID,
          receivingTeamID: newTradeReq.type === 'BilateralTrade' ? receivingTeamID : 0,
          price: +newTradeReq.price
        };

        socket.emit('newTradeRequest', newTradeReqSubmission, (error: IErrorProps['error'], userWarning: string, data: 'complete') => {
          if (error) {
            setError({
              message: 'Failed submitting trade request',
              error
            });
          } else if (userWarning) {
            message.error(userWarning);
          } else if (data === 'complete') {
            message.success(`Trade request submitted`);
            socket.emit('eventLog', {
              type: 'assets',
              log: `Team ${teams.find(team => team.teamID === user.teamID)?.label} submitted trade request ${
                newTradeReqSubmission.tradeType
              }, market: ${newTradeReqSubmission.marketID}, ${newTradeReqSubmission.dealType}, ${newTradeReqSubmission.volume}, ${currency}${
                newTradeReqSubmission.price
              }k `
            });

            setNewTradeReq(prevState => {
              return {
                ...prevState,
                volume: 1,
                price: '',
                sold: false
              };
            });
          }
        });
      } else {
        message.error(
          `Trade request price must between ${
            newTradeReq.tradeType === 'carbonMarket' ? `0 and ${maxTradeAllowed}` : `${minTradeAllowed} and ${maxTradeAllowed} and not equal to 0`
          }`
        );
      }
    };
    const market = activeMarkets.find(aM => aM.marketID === newTradeReq.marketID);
    const volumeUnits = market?.volumeUnits;
    const weightedTradeAverage = tradeWeightedAverage({
      allTrades: completedTrades,
      roundID: settings.currentRound,
      marketType: market?.type || 'powerExchange',
      shortfallPrice:
        market?.type == 'carbonMarket'
          ? settings.carbonTax
          : market?.type === 'hydrogenMarket'
          ? settings.hydrogenShortfallPrice
          : settings.electricityShortfallPrice,
      buybackPrice: market?.type == 'carbonMarket' ? 0 : market?.type === 'hydrogenMarket' ? 0 : settings.electricityBuyBackPrice
    });

    return (
      <div className={styles.tradeRequestContainer}>
        {settings.allowBilaterals || (settings.currentGamePhase === 'strategy' && settings.allowHedging) ? (
          <Radio.Group
            buttonStyle='solid'
            value={newTradeReq.type === 'BilateralTrade'}
            onChange={e => {
              setNewTradeReq({
                ...newTradeReq,
                type: e.target.value ? 'BilateralTrade' : 'OpenTrade'
              });
            }}>
            <Radio.Button value>Bilaterals</Radio.Button>
            {!!settings.allowTrading && currentGamePhase === 'operational' && <Radio.Button value={false}>Open Market</Radio.Button>}
          </Radio.Group>
        ) : (
          <h4>Trade Request</h4>
        )}
        {newTradeReq.type === 'BilateralTrade' && settings.allowMobileBilaterals ? (
          <div className={styles.mobileInfoContainer}>
            <div className={styles.formItem}>
              <label>Market</label>
              <Radio.Group
                className={styles.marketRadio}
                buttonStyle='solid'
                value={newTradeReq.marketID}
                onChange={e => {
                  setTradeType(activeMarkets.find(market => market.marketID === +e.target.value)?.type || activeMarkets[0].type);
                  setNewTradeReq({ ...newTradeReq, marketID: +e.target.value });
                }}>
                {activeMarkets.map(m => (
                  <Radio.Button key={m.marketID} value={m.marketID}>
                    {m.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </div>
            <h4>Mobile Bilaterals</h4>
            <QuickLoginQR />
          </div>
        ) : (
          <form className={styles.form}>
            <div className={styles.formItem}>
              <label>Deal Type</label>

              <Radio.Group
                className={styles.dealTypeRadio}
                buttonStyle='solid'
                value={newTradeReq.dealType}
                onChange={e => setNewTradeReq({ ...newTradeReq, dealType: e.target.value })}>
                <Radio.Button value='sell'>Offers to Sell</Radio.Button>
                <Radio.Button value='buy'>Bids to Buy</Radio.Button>
              </Radio.Group>
            </div>
            <div className={styles.formItem}>
              <label>Market</label>
              <Radio.Group
                className={styles.marketRadio}
                buttonStyle='solid'
                value={newTradeReq.marketID}
                onChange={e => {
                  setTradeType(activeMarkets.find(market => market.marketID === +e.target.value)?.type || activeMarkets[0].type);
                  setNewTradeReq({ ...newTradeReq, marketID: +e.target.value });
                }}>
                {activeMarkets.map(m => (
                  <Radio.Button key={m.marketID} value={m.marketID}>
                    {m.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </div>

            <div className={styles.volPrice}>
              <div className={styles.formItem}>
                <label>Volume</label>

                <Select
                  className={styles.volSelect}
                  value={newTradeReq.volume}
                  onChange={option => setNewTradeReq({ ...newTradeReq, volume: +option })}>
                  {[...new Array(5)].map((a, index) => (
                    <Option key-={index} value={index + 1}>
                      {index + 1} {volumeUnits}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className={styles.formItem}>
                <label htmlFor='price'>Price</label>

                <Input
                  name='price'
                  autoComplete='off'
                  value={newTradeReq.price}
                  prefix={currency}
                  suffix={activeMarkets.find(aM => aM.marketID === newTradeReq.marketID)?.priceUnits}
                  type='text'
                  pattern='^[+-]?[0-9]*'
                  onChange={event =>
                    event.target.validity.valid && (isInteger(Number(event.target.value)) || event.target.value === '-')
                      ? setNewTradeReq({
                          ...newTradeReq,
                          price: event.target.value
                        })
                      : setNewTradeReq({ ...newTradeReq, price: '' })
                  }
                />
              </div>
            </div>
            {newTradeReq.type === 'BilateralTrade' && (
              <div className={styles.formItem}>
                <label>Counterparty</label>
                <Select
                  showSearch
                  placeholder='Select a Team'
                  optionFilterProp='children'
                  className={styles.teamSelect}
                  value={receivingTeamID}
                  onChange={option => setReceivingTeamID(+option)}>
                  {teams
                    .filter(team => team.type === 'PLAYER' && team.teamID !== user.teamID)
                    .map(team => (
                      <Option key={team.teamID} value={team.teamID}>
                        {team.label}
                      </Option>
                    ))}
                </Select>
              </div>
            )}
            <div className={styles.formItem}>
              <Button
                shape='round'
                type='primary'
                htmlType='submit'
                onClick={event => {
                  event.preventDefault();
                  submitTrade();
                }}>
                Submit to Market
              </Button>
            </div>
          </form>
        )}

        {newTradeReq.type === 'OpenTrade' && <LastTradedValues activeMarkets={activeMarkets} currency={currency} />}
        {newTradeReq.type === 'OpenTrade' && <h5>Average Trade Price: {round(weightedTradeAverage)}</h5>}
        <div className={styles.footerButtons}>
          <Radio.Group
            buttonStyle='solid'
            value={teamTradesOnly}
            onChange={e => {
              setTeamTradesOnly(e.target.value);
            }}>
            <Radio.Button value={false}>All Trades</Radio.Button>

            <Radio.Button value>Team Trades</Radio.Button>
          </Radio.Group>
        </div>
      </div>
    );
  }
);
