import { useState, useContext, memo } from 'react';
import { Button, Select, message, Input } from 'antd';
import { IErrorProps, IAsset, BaseTeam, AddAssetTeam } from '@powertrader/schema';

import { PlusOutlined } from '@ant-design/icons';
import { socket } from '../../../../socket/socket';
import { setupDataContext, setErrorContext } from '../../../../context';

import styles from '../Footer.module.css';

const { Option } = Select;

interface IAddAssetToTeam {
  setFooterDisplay: (value: string | undefined) => void;
  selectedTeam: BaseTeam;
  allAssets: IAsset[];
}

export const AddAssetToTeam = memo(({ setFooterDisplay, selectedTeam, allAssets }: IAddAssetToTeam) => {
  const { settings } = useContext(setupDataContext);
  const setError = useContext(setErrorContext);
  const [newAssetID, setNewAssetID] = useState<number>(); // assetID: price:
  const [newAssetPurchasePrice, setNewAssetPurchasePrice] = useState<string>('');
  const [newAssetStrikePrice, setNewAssetStrikePrice] = useState<string>('');

  const handleAddAsset = () => {
    if (!newAssetID) {
      message.error(`No asset selected - cannot add asset ${newAssetID}`);

      return;
    }
    if (isNaN(Number(newAssetStrikePrice)) || isNaN(Number(newAssetPurchasePrice))) {
      message.error(`Prices values are not numbers. Fixed: ${Number(newAssetPurchasePrice)}, Strike: ${Number(newAssetStrikePrice)}`);

      return;
    }

    const [asset] = allAssets.filter(asset => newAssetID === asset.assetID);

    const assetToAdd: AddAssetTeam = {
      price: Number(newAssetPurchasePrice) || 0,
      strikePrice: Number(newAssetStrikePrice) || 0,
      subsidyIncome: Number(newAssetStrikePrice) > 0 ? 0 : undefined,
      selectedAsset: newAssetID,
      selectedTeam: selectedTeam.teamID,
      operation: 'add',
      removeFromAuction: !!asset?.inAuction
    };

    socket.emit('editAsset', assetToAdd, function (error: IErrorProps['error'], data: 'complete') {
      if (error) {
        setError({
          message: `Cannot add asset ${asset.label} to team ${selectedTeam?.label}`,
          error
        });
      } else if (data === 'complete') {
        socket.emit('eventLog', {
          type: 'assets',
          log: `${asset.label} was added to team ${selectedTeam?.label} for ${assetToAdd.price}k Fixed Cost`
        });
      }
    });

    setNewAssetID(undefined);
    setNewAssetPurchasePrice('');
    setNewAssetStrikePrice('');
  };

  return (
    <div style={{ borderColor: selectedTeam.color }} className={styles.footer}>
      <Select
        showSearch
        placeholder='Select an Asset'
        optionFilterProp='children'
        value={newAssetID}
        className={styles.assetSelect}
        dropdownClassName={styles.assetSelectDropdown}
        onChange={option => setNewAssetID(+option)}>
        {allAssets
          .filter(asset => asset.teamID === null)
          .map(asset => {
            return (
              <Option key={asset.assetID} value={asset.assetID}>
                {asset.label} ({asset.typeShortLabel})
              </Option>
            );
          })}
      </Select>

      <Input
        placeholder='fixed'
        onChange={event => setNewAssetPurchasePrice(event.target.value)}
        value={newAssetPurchasePrice}
        prefix={settings.currency}
        suffix={`k/${settings.roundPeriod}`}
        className={styles.assetAdderCost}
      />
      <Input
        placeholder='strike'
        onChange={event => setNewAssetStrikePrice(event.target.value)}
        value={newAssetStrikePrice}
        prefix={settings.currency}
        suffix={`k/GWh`}
        className={styles.assetAdderCost}
      />
      <Button type='primary' size='small' shape='round' icon={<PlusOutlined />} onClick={handleAddAsset} className={styles.addButton} />
      <Button type='primary' size='small' shape='round' className={styles.returnButton} onClick={() => setFooterDisplay(undefined)}>
        Return
      </Button>
    </div>
  );
});
