import { BilateralTrade, IUser } from '@powertrader/schema';
import { notification } from 'antd';

import { useState, useEffect } from 'react';
import { Socket } from 'socket.io-client';

export const useBilateralTradeProvider = ({ socket, currency = '£', user }: { socket: Socket; currency?: string; user?: IUser }) => {
  const [bilateralTrades, setBilateralTrades] = useState<BilateralTrade[]>([]);

  useEffect(() => {
    socket.on('updateBilateralTrades', (data: BilateralTrade[]) => {
      setBilateralTrades(data);
    });

    socket.on('newBilateralTrade', (newTrade: BilateralTrade) => {
      const { offeringTeam, receivingTeam, dealType, volume, market, price } = newTrade;
      const iAmReceiving = receivingTeam?.teamID === user?.teamID;

      setBilateralTrades(prevState => {
        const newState = [...prevState];
        const index = newState.findIndex(trade => trade.id === newTrade.id); //existing trade update
        if (index !== -1) {
          newState[index] = newTrade;
        } else {
          newState.push(newTrade);
        }
        return newState;
      });

      if (iAmReceiving)
        notification.open({
          message: 'Bilateral Trade Request',
          description: `${offeringTeam.label} would like to ${dealType === 'buy' ? 'buy from you' : 'sell to you'} ${volume}${
            market.volumeUnits
          } at ${currency}${price}${market.priceUnits} on the ${market.label} Market. Check the bilaterals page to view this deal.`,
          duration: 0,
          style: { borderBottom: `10px solid ${offeringTeam.color}` }
        });
    });

    socket.on('deleteBilateralTrade', (deleteTrade: BilateralTrade) => {
      setBilateralTrades(prevState => {
        const newState = [...prevState];
        const tradeIndex = newState.findIndex(trade => trade.id === deleteTrade.id);
        if (tradeIndex !== -1) newState[tradeIndex] = deleteTrade;
        return newState;
      });
    });

    socket.on('rejectBilateralTrade', (rejectedTrade: BilateralTrade) => {
      const { offeringTeam, receivingTeam, dealType, volume, market, price } = rejectedTrade;
      const offeredByMyTeam = offeringTeam.teamID === user?.teamID;

      setBilateralTrades(prevState => {
        const newState = [...prevState];
        const tradeIndex = newState.findIndex(trade => trade.id === rejectedTrade.id);
        if (tradeIndex !== -1) newState[tradeIndex] = rejectedTrade;

        return newState;
      });
      console.log('rejectedTrade', rejectedTrade);
      if (offeredByMyTeam)
        notification.open({
          message: 'Bilateral Trade Rejection',
          description: `${receivingTeam?.label} rejected a deal to ${dealType === 'sell' ? 'buy from you' : 'sell to you'} ${volume}${
            market.volumeUnits
          } at ${currency}${price}${market.priceUnits} on the ${market.label} Market`,
          duration: 10,
          style: { borderBottom: `10px solid red` }
        });
    });

    socket.on('acceptBilateralTrade', (acceptedTrade: BilateralTrade) => {
      const { offeringTeam, receivingTeam, dealType, volume, market, price } = acceptedTrade;
      const iAmReceiving = receivingTeam?.teamID === user?.teamID;
      const teamIsOffering = offeringTeam.teamID === user?.teamID;

      setBilateralTrades(prevState => {
        const newState = [...prevState];
        const tradeIndex = newState.findIndex(trade => trade.id === acceptedTrade.id);
        if (tradeIndex !== -1) newState[tradeIndex] = acceptedTrade;
        return newState;
      });

      if (teamIsOffering) {
        notification.open({
          message: 'Bilateral Trade Accepted',
          description: `${receivingTeam?.label} accepted a deal to ${dealType === 'sell' ? 'buy from you' : 'sell to you'} ${volume}${
            market.volumeUnits
          } at ${currency}${price}${market.priceUnits} on the ${market.label} Market`,
          duration: 10,
          style: { borderBottom: `10px solid green` }
        });
        return;
      }

      if (iAmReceiving) {
        notification.open({
          message: 'Bilateral Trade Accepted',
          description: `Your team accepted a deal with ${offeringTeam.label} to ${dealType === 'sell' ? 'buy' : 'sell to them'} ${volume}${
            market.volumeUnits
          } at ${currency}${price}${market.priceUnits} on the ${market.label} Market`,
          duration: 10,
          style: { borderBottom: `10px solid green` }
        });
      }
    });

    socket.on('resetTrading', () => {
      setBilateralTrades([]);
    });
    return () => {
      socket.off('newBilateralTrade');
      socket.off('updateBilateralTrades');
      socket.off('deleteBilateralTrade');
      socket.off('rejectBilateralTrade');
      socket.off('acceptBilateralTrade');
      socket.off('resetTrading');
    };
  }, [currency, socket, user?.teamID]);

  return bilateralTrades;
};

// export const bilateralTradesContext = createContext<BilateralTrade[]>(undefined!);

// export const BilateralTradeProvider: FunctionComponent = ({ children }) => {
//     const bilateralTrades = useBilateralTradeProvider()

//     return <bilaterateralTradesContext.Provider value={bilateralTrades}>{children}</bil...>

// }

// export const useBilateralTrades = () => {
//     const bilateralTrades = useContext(bilateralTradesContext);
//     return bilateralTrades;
// }
