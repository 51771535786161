import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Asset, setErrorContext, setupDataContext, socket, teamAssetsContext } from '@powertrader/core';
import { IAdditionalInformationDisplayProps, IAsset, IErrorProps, UpgradeAssetTypeTeam } from '@powertrader/schema';
import { message, Popconfirm, Button } from 'antd';

import { useContext } from 'react';
import styles from './UpgradeList.module.css';

interface IUpgradeList {
  setAdditionalInformationDisplay: IAdditionalInformationDisplayProps['setState'];
}
export const UpgradeList = ({ setAdditionalInformationDisplay }: IUpgradeList) => {
  const { assetTypes, user, settings } = useContext(setupDataContext);
  const teamAssets = useContext(teamAssetsContext);

  const setError = useContext(setErrorContext);

  interface IUpgradeOption {
    teamAsset: IAsset;
    upgradedType: IAsset;
  }

  const handleUpgradeAsset = (upgradeOption: IUpgradeOption) => {
    const assetToEdit: UpgradeAssetTypeTeam = {
      selectedAsset: upgradeOption.teamAsset.assetID,
      newType: upgradeOption.upgradedType.typeID,
      selectedTeam: user.teamID,
      operation: 'edit'
    };
    socket.emit('editAsset', assetToEdit, (error: IErrorProps['error'], data: 'complete') => {
      if (error) {
        message.error(`Oops, somthing went wrong - speak to the heuristic team in the chat`);
        setError({
          message: `Cannot edit asset  ${upgradeOption.teamAsset.label} from upgrade area`,
          error
        });
      } else if (data === 'complete') {
        message.success(`${upgradeOption.teamAsset.label} was change to a ${upgradeOption.upgradedType.typeLabel}`);
        socket.emit('eventLog', {
          type: 'assets',
          log: `${upgradeOption.teamAsset.label} was change to a ${upgradeOption.upgradedType.typeLabel} by ${user.userName}`
        });
      }
    });
  };
  const upgradeOptions: IUpgradeOption[] = [];
  teamAssets.forEach(teamAsset => {
    if (!teamAsset.upgradableTypes) return;
    teamAsset.upgradableTypes.forEach(upgradeTypeID => {
      const upgradedType = assetTypes.find(assetType => assetType.typeID === upgradeTypeID);
      if (upgradedType)
        upgradeOptions.push({
          teamAsset,
          upgradedType: {
            ...teamAsset,
            ...upgradedType,
            typeLabel: upgradedType.label,
            label: teamAsset.label
          }
        });
    });
  });

  return (
    <div className={styles.container}>
      <h2 className={styles.sectionTitle}>Upgrade List</h2>

      <h3 className={styles.close} onClick={() => setAdditionalInformationDisplay(undefined)}>
        Close
      </h3>
      {settings.currentGamePhase === 'strategy' ? (
        <p>To upgrade an asset, click "Upgrade Asset" below.</p>
      ) : (
        <p>You can only upgrade assets in the strategy phase.</p>
      )}
      <div className={styles.assetDisplay}>
        {upgradeOptions.map((upgradeOption, index) => {
          return (
            <div key={index} className={styles.upgradeAssetColumn}>
              <Asset demo details='show' asset={upgradeOption.teamAsset} key={upgradeOption.teamAsset.typeID} className={styles.asset} />
              {settings.currentGamePhase === 'strategy' ? (
                <Popconfirm
                  title='Upgrade Asset - Are you sure?'
                  placement='bottomRight'
                  onConfirm={() => handleUpgradeAsset(upgradeOption)}
                  okText='Yes'
                  cancelText='No'>
                  <div className={styles.upgradeButtonRow}>
                    <FontAwesomeIcon icon={faArrowDown as IconProp} className={styles.downArrow} />
                    <Button ghost type='default' shape='round' size='small'>
                      Upgrade Asset
                    </Button>
                    <FontAwesomeIcon icon={faArrowDown as IconProp} className={styles.downArrow} />
                  </div>
                </Popconfirm>
              ) : (
                <FontAwesomeIcon icon={faArrowDown as IconProp} className={styles.downArrow} />
              )}
              <Asset demo details='show' asset={upgradeOption.upgradedType} key={upgradeOption.upgradedType.typeID} className={styles.asset} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
