import { useState, useEffect, useContext, memo, ChangeEvent } from 'react';

import { IUser, IErrorProps, IGameProgress, ITeamRoundData, IUpdateTeamStrategyData } from '@powertrader/schema';
import { Button, Input, message } from 'antd';
import { throttle } from 'underscore';

import { setErrorContext, teamRoundDataContext, socket } from '@powertrader/core';

import styles from './StrategyStatement.module.css';

const { TextArea } = Input;
interface IStrategyStatement {
  user: IUser;
  roundID: IGameProgress['roundID'];
}
interface IUpdateStatement {
  userID: IUser['userID'];
  teamID: IUser['teamID'];
  strapline: ITeamRoundData['strapline'];
  strategyStatement: ITeamRoundData['strategyStatement'];
  name: string;
  submitted: boolean;
}

export const StrategyStatement = memo(({ user, roundID }: IStrategyStatement) => {
  const { teamID, color } = user;
  const teamRoundData = useContext(teamRoundDataContext);

  const thisRound = teamRoundData.find(tr => tr.roundID === roundID);
  const [strapline, setStrapline] = useState(thisRound?.strapline);
  const [strategyStatement, setStrategyStatement] = useState(thisRound?.strategyStatement);
  const [submittedThisRound, setSubmittedThisRound] = useState<boolean>(false);

  const setError = useContext(setErrorContext);

  const syncType = throttle((data: IUpdateStatement) => {
    socket.emit('updateTeamStatements', data);
  }, 500);

  const handleTyping = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    const { value, name } = event.target;
    if (name === 'strapline') setStrapline(value);
    if (name === 'strategyStatement') setStrategyStatement(value);

    syncType({
      strapline: name === 'strapline' ? value : '',
      strategyStatement: name === 'strategyStatement' ? value : '',
      teamID,
      name,
      userID: user.userID,
      submitted: false
    });
  };
  useEffect(() => {
    socket.on('updateStatement', (data: IUpdateStatement) => {
      if (data.submitted) {
        setSubmittedThisRound(true);
        setTimeout(() => {
          setSubmittedThisRound(false);
        }, 5000);
        setStrapline(data.strapline);
        setStrategyStatement(data.strategyStatement);
      } else if (data.userID !== user.userID) {
        if (data.name === 'strapline') setStrapline(data.strapline);
        if (data.name === 'strategyStatement') setStrategyStatement(data.strategyStatement);
      }
    });
    return () => {
      socket.off('updateStatement');
    };
  }, [user.userID]);

  const handleSubmit = () => {
    if (!strapline) message.warning('No strapline provided');
    if (!strategyStatement) message.warning('No strategy statement provided');
    const strategySubmission: IUpdateTeamStrategyData = {
      strapline: strapline || 'No strapline provided',
      strategyStatement: strategyStatement || 'No strategy statement provided',
      teamID,
      roundID,
      submitted: true
    };
    socket.emit('teamStrategyStatements', strategySubmission, function (error: IErrorProps['error'], data: string) {
      if (error) {
        setError({ message: 'Cannot set strategy statement', error });
      } else if (data === 'complete') {
        message.success('Strategy Statement Submitted');
      }
    });
  };

  return (
    <>
      <h2 className={styles.sectionTitle}>Strategy Statement</h2>

      <div className={styles.container} style={{ borderColor: color }}>
        <h5>
          In order to influence your shareholders use the fields below to enter a Strategy Statement. <br /> <br />
          Keep it fresh and update it each round to continue influencing shareholder.
        </h5>
        <label htmlFor='strapline'>Strapline</label>
        <Input
          placeholder='Strapline (40 characters)'
          value={strapline || ''}
          name='strapline'
          maxLength={40}
          onChange={event => handleTyping(event)}
          className={styles.straplineInput}
        />

        <label htmlFor='strategyStatement'>Strategy Statement</label>
        <TextArea
          placeholder='Strategy Statement (200 characters)'
          value={strategyStatement || ''}
          name='strategyStatement'
          maxLength={300}
          onChange={event => handleTyping(event)}
          className={styles.strategyStatementInput}
        />

        <Button shape='round' type='primary' className={styles.submit} onClick={handleSubmit}>
          Send to Shareholders
        </Button>
        {submittedThisRound && <h5 className={styles.submitMessage}>Team statements updated, you may resubmit.</h5>}
      </div>
    </>
  );
});
