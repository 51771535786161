import { useContext, memo } from 'react';
import { ISetupData, IGameProgress, ITeamScore, IAsset } from '@powertrader/schema';

import { round, sumBy } from 'lodash';

import { setupDataContext, gameProgressContext } from '../../../context';

import styles from './TeamSummaryCard.module.css';
import { SummaryCardCell } from './SummaryCardCell';

interface ITeamSummaryTotalRow {
  teamScores: ITeamScore[];
  genAssets: IAsset[];
}

export const TeamSummaryTotalRow = memo(({ teamScores, genAssets }: ITeamSummaryTotalRow) => {
  const { settings } = useContext<ISetupData>(setupDataContext);
  const { scenarioID } = useContext<IGameProgress>(gameProgressContext);

  let maxPotentialGen = 0;
  for (const asset of genAssets) {
    maxPotentialGen += asset.typeScenarioLimits.find(l => l.scenarioID === scenarioID)?.energyMarketLimit || 0;
  }

  const unusedGeneration = maxPotentialGen - sumBy(teamScores, ts => ts.totals.generation);

  const hydrogenStorage = sumBy(teamScores, ts => ts.hydrogen.currentStored);
  const customerTotalShortR = sumBy(teamScores, ts => ts.customerTotalShortR);
  const generation = sumBy(teamScores, ts => ts.totals.generation);
  const profit = sumBy(teamScores, ts => ts.totals.profit);
  const carbonCertificates = round(
    sumBy(teamScores, ts => ts.totals.carbonCertificates),
    2
  );

  return (
    <>
      <div data-testid={`team-summary-card-total-row`} className={styles.innerCard} style={{ border: '1px solid black', marginTop: '-5px' }}>
        <div className={styles.summaryContainer}>
          <SummaryCardCell header style={{ width: '100px', border: 'none' }}>
            <h5>TOTALS</h5>
          </SummaryCardCell>
          {Array(3)
            .fill('')
            .map((_, i) => {
              const marketTotalBalance = sumBy(teamScores, ts => ts.wholesaleBalance[i].balance);
              return (
                <SummaryCardCell key={i} colorToDisplay={{ value: round(marketTotalBalance), greenLimit: 0, redLimit: -2 }}>
                  {round(marketTotalBalance)}
                </SummaryCardCell>
              );
            })}
          <SummaryCardCell colorToDisplay={{ value: round(hydrogenStorage), greenLimit: 0, redLimit: 3 }}>{hydrogenStorage}</SummaryCardCell>
          <SummaryCardCell colorToDisplay={{ value: round(customerTotalShortR), greenLimit: 0, redLimit: 3 }} style={{ width: '12%' }}>
            {round(customerTotalShortR)}
          </SummaryCardCell>
          <SummaryCardCell colorToDisplay={{ value: round(unusedGeneration), greenLimit: 0, redLimit: 100 }} style={{ width: '13%' }}>
            <>
              {round(generation)} / {round(maxPotentialGen)}
            </>
          </SummaryCardCell>
          <SummaryCardCell colorToDisplay={{ value: round(profit), greenLimit: 0, redLimit: -100 }}>
            <>
              {settings.currency} {round(profit)}k
            </>
          </SummaryCardCell>
          <SummaryCardCell colorToDisplay={{ value: round(carbonCertificates, 2), greenLimit: 0, redLimit: -4 }}>
            <>
              {round(carbonCertificates, 2)} kt.CO<sub>2</sub>
            </>
          </SummaryCardCell>
        </div>
      </div>
    </>
  );
});
