import { CheckOutlined, DeleteOutlined, DownOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { AssignAssetTeam, IAsset, IErrorProps } from '@powertrader/schema';
import { Button, Input, Radio, Select, message } from 'antd';
import classNames from 'classnames';
import { memo, useContext, useEffect, useState } from 'react';

import { setErrorContext, setupDataContext } from '../../../context';
import { socket } from '../../../socket/socket';
import styles from './AuctionControl.module.css';

interface IAuctionControl {
  allAssets: IAsset[];
}
export const AuctionControl = memo(({ allAssets }: IAuctionControl) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { teams, settings } = useContext(setupDataContext);

  const setError = useContext(setErrorContext);
  const [newAsset, setNewAsset] = useState<IAsset | undefined>(); // AssetID
  const [auctionAssets, setAuctionAssets] = useState<IAsset[]>([]);
  const [purchaseTeam, setPurchaseTeam] = useState<number | undefined>();
  const [assetIDToSell, setAssetIDToSell] = useState<number | undefined>(); // AssetID
  const [sellPrice, setSellPrice] = useState<number | undefined>(); // SellPrice
  const [sellPriceText, setSellPriceText] = useState<string>(''); // SellPrice intermediary text
  const [saleType, setSaleType] = useState<'fixed' | 'cfd'>('fixed');
  const [auctionRestore, setAuctionRestore] = useState();
  const { Option } = Select;
  const teamsArray = teams.filter(team => team.type !== 'ADMIN');

  const handleAddAsset = () => {
    if (newAsset) {
      const assetToAdd = newAsset;
      const asset = { operation: 'add', asset: assetToAdd };
      socket.emit('editAssetInAuction', asset, (error: IErrorProps['error'], data: 'complete') => {
        if (error) {
          setError({
            message: `Cannot add asset ${assetToAdd?.label} to auction`,
            error
          });
        } else if (data === 'complete') {
          socket.emit('eventLog', {
            type: 'assets',
            log: `${assetToAdd?.label} was added to the auction`
          });
        }
      });
    } else {
      message.error('Please select an asset to add to the auction');
      socket.emit('eventLog', {
        type: 'assets',
        log: `No asset added, you cannot auction the concept of an auction!`
      });
    }
    setNewAsset(undefined);
  };

  const handleSellAsset = () => {
    if (!purchaseTeam || !assetIDToSell) {
      message.error(`Team or Asset not selected`);
    } else if (sellPrice === undefined) {
      message.error(`Sell price must be set, for a free asset type 0`);
    } else {
      setAssetIDToSell(undefined);
      const asset = allAssets.find(a => assetIDToSell === a.assetID);

      const assetToAdd: AssignAssetTeam = {
        operation: 'sale',
        selectedAsset: assetIDToSell,
        selectedTeam: purchaseTeam,
        price: saleType === 'fixed' ? +(sellPrice || 0) : 0,
        strikePrice: saleType === 'cfd' ? +(sellPrice || 0) : 0,
        subsidyIncome: +(sellPrice || 0) > 0 ? 0 : undefined,
        removeFromAuction: true,
        soldAtAuction: true,
        notification: `${asset?.label} sold to ${teams.find(team => purchaseTeam === team.teamID)?.label} for ${settings.currency} ${+(
          sellPrice || 0
        )} k`
      };

      socket.emit('editAsset', assetToAdd, (error: IErrorProps['error'], data: 'complete') => {
        if (error) {
          setError({
            message: `Failed selling asset ${asset?.label} to team ${
              teams.find(team => purchaseTeam === team.teamID)?.label
            }. This may be becuase the asset is taken`,
            error
          });
        } else if (data === 'complete') {
          const completemsg = `${asset?.label} was sold to team ${teams.find(team => purchaseTeam === team.teamID)?.label} for ${
            saleType === 'cfd' ? 'a strike price of ' : ''
          }${assetToAdd.price}k${saleType === 'fixed' ? ' Fixed Cost' : ''}`;
          message.success(completemsg);
          socket.emit('eventLog', {
            type: 'assets',
            log: completemsg
          });
        }
      });
      setPurchaseTeam(undefined);
      setSellPrice(undefined);
      setSellPriceText('');
    }
  };
  const handleDeleteAsset = (assetToAdd: IAsset) => {
    const asset = { operation: 'remove', asset: assetToAdd };
    socket.emit('editAssetInAuction', asset, (error: IErrorProps['error'], data: 'complete') => {
      if (error) {
        setError({
          message: `Failed removing asset ${assetToAdd.label} from auction`,
          error
        });
      } else if (data === 'complete') {
        socket.emit('eventLog', {
          type: 'assets',
          log: `${assetToAdd.label} was removed from auction`
        });
      }
    });
  };

  const handleAuctionRestore = () => {
    socket.emit('recallAuctionPreset', auctionRestore, (error: IErrorProps['error'], allAssetsAdded: IAsset[]) => {
      if (error) {
        setError({
          message: `Failed getting auction ${auctionRestore} assets`,
          error
        });
      } else {
        socket.emit('eventLog', {
          type: 'assets',
          log: `Auction preset ${auctionRestore} recalled`
        });
        if (!allAssetsAdded) {
          message.warning('Some recalled assets are taken, these have been removed from auction');
        }
      }
    });
  };

  const handleAuctionSave = () => {
    if (!auctionRestore) {
      message.error('Please select an auction to save');
    } else {
      socket.emit('saveAuctionPreset', auctionRestore, auctionAssets, (error: IErrorProps['error'], data: 'complete') => {
        if (error) {
          setError({
            message: `Failed saving auction ${auctionRestore} assets`,
            error
          });
        } else if (data === 'complete') {
          message.success(`Auction preset ${auctionRestore} was saved`);
          socket.emit('eventLog', {
            type: 'assets',
            log: `Auction preset ${auctionRestore} recalled`
          });
        }
      });
    }
  };

  const handleSellNext = (assetToSell: IAsset) => {
    const asset = { operation: 'sellNext', asset: assetToSell };
    socket.emit('editAssetInAuction', asset, (error: IErrorProps['error'], data: 'complete') => {
      if (error) {
        setError({
          message: `Cannot select asset ${assetToSell?.label} to sell in next auction`,
          error
        });
      } else if (data === 'complete') {
      }
    });
  };

  useEffect(() => {
    setAuctionAssets(
      allAssets.filter(asset => asset.inAuction === 1) // hides assets already taken
    );
    setNewAsset(undefined);
  }, [allAssets]);

  return (
    <div className={styles.innerCard}>
      <div className='componentHeader'>
        <h3>Auction Controls</h3>{' '}
        <DownOutlined style={isExpanded ? {} : { transform: 'rotate(180deg)' }} onClick={() => setIsExpanded(!isExpanded)} />
      </div>
      {isExpanded && (
        <div>
          <div className={styles.saleContainer}>
            <div className={styles.auctionMode}>
              <h5>Sale type:</h5>
              <Radio.Group
                value={saleType}
                onChange={val => setSaleType(val.target.value)}
                options={[
                  { label: 'Fixed', value: 'fixed' },
                  { label: 'CFD', value: 'cfd' }
                ]}
                optionType='button'
                buttonStyle='solid'
              />
            </div>
            {auctionAssets.length > 0 && (
              <div className={styles.assetSell}>
                <Select
                  showSearch
                  placeholder='Team'
                  optionFilterProp='children'
                  className={styles.teamSelect}
                  value={purchaseTeam}
                  onChange={option => setPurchaseTeam(+option)}>
                  {teamsArray.map(team => (
                    <Option key={team.teamID} value={team.teamID}>
                      {team.label}
                    </Option>
                  ))}
                </Select>
                <Input
                  type='text'
                  value={sellPriceText}
                  onChange={event => {
                    setSellPriceText(event.target.value.replace(/[^-?0-9]/gi, ''));
                  }}
                  onBlur={() => setSellPrice(+sellPriceText)}
                  prefix={settings.currency}
                  suffix={saleType === 'cfd' ? 'k/GWh' : `k/${settings.roundPeriod}`}
                  className={styles.assetPrice}
                />
                <Button type='primary' size='small' shape='round' disabled={settings.currentGamePhase !== 'strategy'} onClick={handleSellAsset}>
                  sell
                </Button>
              </div>
            )}
          </div>
          <div className={styles.auctionList}>
            {auctionAssets.length === 0 && (
              <div>
                <p>No Assets in Auction</p>
              </div>
            )}
            {auctionAssets.map(asset => {
              return (
                <div key={asset.assetID} className={classNames([styles.auctionItem, assetIDToSell === asset.assetID && styles.selectedAuctionItem])}>
                  <p onClick={() => setAssetIDToSell(asset.assetID)}>
                    {asset.label} ({asset.typeShortLabel})
                  </p>
                  <div className={styles.assetButtons}>
                    <Button
                      type='primary'
                      size='small'
                      className={styles.button}
                      shape='round'
                      onClick={() => handleDeleteAsset(asset)}
                      icon={<DeleteOutlined />}
                    />

                    <Button type='primary' size='small' className={styles.button} shape='round' onClick={() => handleSellNext(asset)}>
                      {settings.currency}
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
          <div className={styles.assetAdder}>
            <Select
              showSearch
              placeholder='Add Asset to Auction'
              optionFilterProp='children'
              className={styles.assetSelector}
              value={newAsset?.assetID}
              onChange={option => {
                setNewAsset(allAssets.find(asset => asset.assetID === option));
              }}
              dropdownClassName={styles.assetSelectorDropdown}>
              {allAssets
                .filter(asset => asset.teamID === null && auctionAssets.find(aucAsset => asset.assetID === aucAsset.assetID) === undefined)
                .map(asset => {
                  return (
                    <Option key={asset.assetID} value={asset.assetID}>
                      {asset.label} ({asset.typeShortLabel})
                    </Option>
                  );
                })}
            </Select>
            <Button type='primary' size='small' shape='round' icon={<PlusOutlined />} onClick={handleAddAsset} className={styles.addButton} />
          </div>
          <div className={styles.savedAuctionArea}>
            <h5>Recall: </h5>
            <Select
              placeholder='Auction No'
              optionFilterProp='children'
              className={styles.auctionSelect}
              value={auctionRestore}
              onChange={option => setAuctionRestore(option)}>
              {[...Array(4)].map((a, index) => (
                <Option key={index} value={index + 1}>
                  {index + 1}
                </Option>
              ))}
            </Select>
            <div className={styles.footerButtons}>
              <Button type='primary' size='small' shape='round' className={styles.button} onClick={handleAuctionRestore} icon={<CheckOutlined />} />
              <Button type='primary' size='small' onClick={handleAuctionSave} className={styles.button} shape='round' icon={<SaveOutlined />} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
});
