import { useContext, memo } from 'react';
import { ITeamRoundDataToDisplay } from '@powertrader/schema';
import { round } from 'lodash';
import styles from './ReviewTable.module.css';

import { setupDataContext, controlDataContext } from '../../context';
import ordinal from 'ordinal';

interface IReviewTable {
  thisRoundScores: ITeamRoundDataToDisplay[];
  teamRoundDataToDisplay: ITeamRoundDataToDisplay[];
  display: 'sharePrice' | 'emissions' | 'carbonCertificates' | 'noOfTrades';
}

export const ReviewTable = memo(({ thisRoundScores, teamRoundDataToDisplay, display }: IReviewTable) => {
  const { teams, settings } = useContext(setupDataContext);

  let sortedTeams = thisRoundScores
    .sort((a, b) => b[display] - a[display])
    .map(trd => ({
      teamID: trd.teamID,
      label: teams.find(t => t.teamID === trd.teamID)?.label
    }));

  if (display === 'emissions') {
    sortedTeams = sortedTeams.reverse();
  }

  return (
    <div className={styles.reviewPageContainer}>
      <table className={styles.balanceTable}>
        <thead className={styles.header}>
          <tr>
            <th />
            {sortedTeams.map((t, i) => (
              <th key={i}>
                <p className={styles.rank}>{ordinal(i + 1)}</p> {t.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {display === 'sharePrice' && (
            <tr>
              <td>Start</td>
              {sortedTeams.map((t, i) => (
                <td key={i}> {settings.startingSharePrice}</td>
              ))}
            </tr>
          )}
          {Array(settings.noOfRounds)
            .fill('')
            .map(
              (_, i) =>
                i + 1 <= settings.currentRound && (
                  <>
                    {i + 1 === settings.currentRound && (
                      <tr className={styles.reviewTableDifference}>
                        <td></td>
                        {sortedTeams.map(t => {
                          const lastRoundTeamSharePrice =
                            settings.currentRound === 1
                              ? settings.startingSharePrice
                              : round(teamRoundDataToDisplay.find(trd => trd.roundID === i && trd.teamID === t.teamID)?.[display] || 0, 2);
                          const thisRoundTeamSharePrice = round(
                            teamRoundDataToDisplay.find(trd => trd.roundID === i + 1 && trd.teamID === t.teamID)?.[display] || 0,
                            2
                          );

                          const difference = round(thisRoundTeamSharePrice - lastRoundTeamSharePrice);
                          return (
                            <td style={{ color: difference < 0 ? 'red' : 'green' }} className={styles.reviewTableDifferenceValue}>
                              {difference > 0 ? '+' + difference : difference}
                            </td>
                          );
                        })}
                      </tr>
                    )}
                    <tr key={i + 1}>
                      <td>Round {i + 1}</td>
                      {sortedTeams.map(t => (
                        <td key={t.teamID}>
                          {round(teamRoundDataToDisplay.find(trd => trd.roundID === i + 1 && trd.teamID === t.teamID)?.[display] || 0, 2)}
                        </td>
                      ))}
                    </tr>
                  </>
                )
            )}
        </tbody>
      </table>
    </div>
  );
});
