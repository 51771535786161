import { useState, useEffect, useContext, memo } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Steps, Button as AntButton, Popconfirm, message, Radio, notification } from 'antd';

import { ITeamScore, IErrorProps, ISettingsDetails, ISettings } from '@powertrader/schema';

import { socket } from '../../../socket/socket';
import { setErrorContext, setupDataContext } from '../../../context';

import styles from './RoundControl.module.css';

const { Step } = Steps;
interface IRoundControl {
  teamScores: ITeamScore[];
}
export const RoundControl = memo(({ teamScores }: IRoundControl) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { settingDetails, settings } = useContext(setupDataContext);
  const setError = useContext(setErrorContext);
  const [currentSetup, setCurrentSetup] = useState(0);
  const [currentPhase, setCurrentPhase] = useState<undefined | number>();
  const [startRoundWith, setStartRoundWith] = useState<'strategy' | 'auction' | 'plenary'>('strategy');
  const [isProgressing, setIsProgressing] = useState(false);

  let settingsToUpdate: ISettingsDetails[] = [];

  useEffect(() => {
    if (!isProgressing) {
      if (settings.currentGamePhase === 'setup') {
        setCurrentSetup(0);
        setCurrentPhase(undefined);
        return;
      }
      if (settings.currentGamePhase === 'portfolio') {
        setCurrentSetup(1);
        setCurrentPhase(undefined);
        return;
      }

      if (settings.currentGamePhase === 'demo') {
        setCurrentSetup(2);
        setCurrentPhase(undefined);
        return;
      }
      if (settings.currentGamePhase === 'strategy') {
        setCurrentSetup(3);
        setCurrentPhase(0);
        return;
      }
      if (settings.currentGamePhase === 'operational') {
        setCurrentSetup(3);
        setCurrentPhase(1);
        return;
      }
      if (settings.currentGamePhase === 'review') {
        setCurrentSetup(3);
        setCurrentPhase(2);
      }
    }
  }, [isProgressing, settings]);

  const updateNewSettingsArray = (key: keyof ISettings, value: ISettingsDetails['value']) => {
    const newValue = value === false ? 0 : value === true ? 1 : value;
    const tempSettingIndex = settingsToUpdate.findIndex(s => s.name === key);
    if (tempSettingIndex !== -1) {
      settingsToUpdate[tempSettingIndex].value = newValue;
    } else {
      const setting = settingDetails.find(s => s.name === key);
      if (setting) settingsToUpdate.push({ ...setting, value: newValue });
    }
  };

  const updateSetting = (success: string) => {
    socket.emit('updateSettings', settingsToUpdate, (error: IErrorProps['error'], data: 'complete') => {
      if (error) {
        setError({
          message: `Failed saving settings`,
          error
        });
      } else if (data === 'complete') {
        message.success(success);
        socket.emit('eventLog', {
          type: 'system',
          log: `Setting saved ${settingsToUpdate.map(s => `${s.label}: ${s.value},`)}`
        });
      }
    });
    settingsToUpdate = [];
  };

  const handleNextRound = (startGame: boolean) => {
    setIsProgressing(true);
    if (startGame) {
      setCurrentSetup(3);
    }
    const savedValues = [];
    if (settings.currentRound !== 0) {
      for (const team of teamScores) {
        const { teamID, totals, sharePrice } = team;

        savedValues.push({
          roundID: settings.currentRound,
          teamID,
          profit: totals.profit,
          emissions: totals.emissions,
          carbonCertificates: totals.carbonCertificates,
          sharePrice: sharePrice.thisRoundSharePrice,
          noOfTrades: totals.noOfTrades,
          hydrogenStored: team.hydrogen.currentStored
        });
      }
    }
    const resultsToSave = {
      teamScores: savedValues,
      roundID: settings.currentRound,
      noOfTeams: settings.noOfTeams,
      startingBalance: settings.startingBalance,
      startGame
    };

    socket.emit('saveRoundResults', resultsToSave, (error: IErrorProps['error'], data: 'complete') => {
      if (error) {
        setError({
          message: `Failed saving team scores - Please refresh and try again`,
          error
        });
      } else if (data === 'complete') {
        if (settings.currentRound !== 0 && !startGame) {
          message.success('Team Scores Saved');
        }
        socket.emit('eventLog', {
          type: 'system',
          log: `Round Score Saved to DB`
        });

        if (startGame || settings.currentGamePhase === 'review') {
          // starting of a normal
          if (!startGame) {
            // normal round end
            updateNewSettingsArray('currentRound', settings.currentRound + 1);
          } else {
            // demo round end so don't increment
            updateNewSettingsArray('currentRound', 1);
            socket.emit('demoRoundEnded');
          }
          updateNewSettingsArray('allowStrategyInput', true);
          updateNewSettingsArray('allowAssetLoading', false);
          updateNewSettingsArray('returnToPlenary', false);
          updateNewSettingsArray('currentGamePhase', 'strategy');

          if (startRoundWith === 'auction') {
            updateNewSettingsArray('isAuctionActive', true);
          } else if (startRoundWith === 'plenary') {
            updateNewSettingsArray('returnToPlenary', true);
          }
          setStartRoundWith('strategy'); // reset radio buttons
          updateSetting(`Round ${settingsToUpdate.find(s => s.name === 'currentRound')?.value || 1} Strategy Phase Initiated`);
        } else {
          // demo round no trading
          updateNewSettingsArray('allowAssetLoading', true);
          // updateNewSettingsArray('currentRound', 1);
          updateNewSettingsArray('returnToPlenary', false);

          updateNewSettingsArray('currentGamePhase', 'demo');

          updateSetting(`Demo Phase Started`);
        }

        setCurrentPhase(0);
      }
      setIsProgressing(false);
    });
  };

  const onSetupChange = (current: number) => {
    setIsProgressing(true);
    if (current === 0 && settings.currentGamePhase !== 'demo') {
      // Game Setup Phase

      updateNewSettingsArray('allowPlayerLogin', false);
      updateNewSettingsArray('currentGamePhase', 'setup');

      updateSetting(`Game Setup`);
      setCurrentSetup(0);
      message.info('Changing game settings now may require logged users to refresh');
      setIsProgressing(false);
      return;
    }
    if (current === 1 && settings.currentGamePhase !== 'demo') {
      // Portfolio Phase

      updateNewSettingsArray('allowPlayerLogin', true);
      updateNewSettingsArray('currentGamePhase', 'portfolio');

      updateSetting(`Portfolio Selection Enabled`);
      setCurrentSetup(1);
      setIsProgressing(false);
      return;
    }
    if (settings.currentGamePhase === 'portfolio' && current === 2) {
      // Begin Demo Round
      setCurrentSetup(2);
      handleNextRound(false);
    }
  };

  const onRoundChange = (current: number) => {
    if (current === 0) {
      // strategy phase
      updateNewSettingsArray('allowStrategyInput', true);
      updateNewSettingsArray('allowAssetLoading', false);
      updateNewSettingsArray('returnToPlenary', false);
      updateNewSettingsArray('currentGamePhase', 'strategy');
      updateSetting(`Strategy Phase Initiated`);
      setCurrentPhase(current);
      return;
    }
    if (current === 1) {
      // operational phase
      updateNewSettingsArray('allowStrategyInput', false);
      updateNewSettingsArray('allowAssetLoading', true);
      updateNewSettingsArray('isAuctionActive', false);
      updateNewSettingsArray('returnToPlenary', false);
      updateNewSettingsArray('allowVisibleCostDetails', true);
      updateNewSettingsArray('currentGamePhase', 'operational');

      updateSetting(`Operational Phase Initiated`);
      setCurrentPhase(current);
      return;
    }
    if (current === 2) {
      // review phase
      updateNewSettingsArray('allowStrategyInput', false);
      updateNewSettingsArray('allowAssetLoading', false);
      updateNewSettingsArray('returnToPlenary', true);
      updateNewSettingsArray('currentGamePhase', 'review');

      updateSetting(`Review Phase Initiated`);
      setCurrentPhase(current);
    }
  };

  return (
    <div className={styles.innerCard}>
      <div className='componentHeader'>
        <h3>Round Phase Controls </h3>{' '}
        <DownOutlined style={isExpanded ? {} : { transform: 'rotate(180deg)' }} onClick={() => setIsExpanded(!isExpanded)} disabled={isProgressing} />
      </div>
      {isExpanded && (
        <>
          {currentSetup < 3 ? (
            <>
              <Steps className={styles.stepper} current={currentSetup} onChange={onSetupChange} direction='vertical'>
                <Step title='Game Setup' description='Setup and configure your new game.' disabled={isProgressing || currentSetup === 2} />
                <Step
                  title='Portfolio Selection'
                  description='Assign portfolios to teams.'
                  onClick={() => !isProgressing && currentSetup !== 2 && notification.info({ message: 'Demo teams should now be set', duration: 0 })}
                  disabled={isProgressing || currentSetup === 2}
                />

                <Step title='Demo Round' description='None scoring, skippable round.' disabled={isProgressing || currentSetup === 0} />
              </Steps>

              <div className={styles.startGameButtons}>
                <Popconfirm
                  title='Are you sure?'
                  placement='top'
                  onConfirm={() => handleNextRound(true)}
                  okText='Yes'
                  cancelText='No'
                  disabled={currentSetup < 1 || isProgressing}>
                  <AntButton className={styles.buttons} disabled={currentSetup < 1 || isProgressing} type='primary' size='small' shape='round'>
                    Start Main Game
                  </AntButton>{' '}
                </Popconfirm>
              </div>
            </>
          ) : (
            <>
              <div className={styles.buttons}>
                <Popconfirm
                  title='Are you sure?'
                  placement='top'
                  onConfirm={() => handleNextRound(false)}
                  okText='Yes'
                  cancelText='No'
                  disabled={!(currentPhase === 2 && settings.noOfRounds > settings.currentRound) || isProgressing}>
                  <AntButton
                    className={styles.newRoundButton}
                    disabled={!(currentPhase === 2 && settings.noOfRounds > settings.currentRound) || isProgressing}
                    type='primary'
                    size='small'
                    shape='round'>
                    Start Next Round
                  </AntButton>
                </Popconfirm>
                {/* <Tooltip
                    placement='left'
                    title='Start next round with...'
                    color='#1a86f1'> */}
                <Radio.Group
                  size='small'
                  style={{ marginTop: 5, zIndex: 0 }}
                  disabled={!(currentPhase === 2 && settings.noOfRounds > settings.currentRound)}
                  options={[
                    { label: 'Strategy', value: 'strategy' },
                    { label: 'Auction', value: 'auction' },
                    { label: 'Plenary', value: 'plenary' }
                  ]}
                  onChange={e => {
                    setStartRoundWith(e.target.value);
                  }}
                  optionType='button'
                  value={startRoundWith}
                />
                {/* </Tooltip> */}
              </div>
              <Steps className={styles.stepper} current={currentPhase} onChange={onRoundChange} direction='vertical'>
                <Step title='Strategy Phase' description='Allow Teams to Write a Strategy.' disabled={isProgressing} />
                <Step title='Operational Phase' description='Allow Teams to Asset Load and Trade.' disabled={isProgressing} />
                <Step title='Review Phase' description='Lock All Controls for Review Phase.' disabled={isProgressing} />
              </Steps>
            </>
          )}
        </>
      )}
    </div>
  );
});
