import styles from './CentreStage.module.css';

interface ICSSingleHeading {
  text: string;
  logo?: boolean;
}
export const CSSingleHeading = ({ text, logo }: ICSSingleHeading) => {
  return (
    <>
      <h2 style={{ marginTop: '20%' }} className={styles.sectionTitle}>
        {text}
      </h2>
      {logo && (
        <div className={styles.logoContainer}>
          <img className={styles.logo} alt='Heuristic Logo' src='/logo.png' />
        </div>
      )}
    </>
  );
};
