import { Select } from 'antd';
import { round } from 'lodash';
import { Market, IAsset, IAssetType } from '@powertrader/schema';
import { memo, useContext } from 'react';
import { setupDataContext, gameProgressContext, setErrorContext } from '../../context';
import styles from './Asset.module.css';
import { handleLoadChange } from './handleLoadChange';

const { Option } = Select;

interface IAssetLoadSelect {
  market: Market;
  asset: IAsset | IAssetType;
  demo: boolean;
  scenarioTypeLimit: number;
  scenarioID: number;
  disabled?: boolean;
}
export const AssetLoadSelect = memo(({ market, asset, scenarioTypeLimit, demo, scenarioID, disabled }: IAssetLoadSelect) => {
  const { settings, markets } = useContext(setupDataContext);
  const { roundID } = useContext(gameProgressContext);
  const setError = useContext(setErrorContext);

  const { flexible, maxScenarioMarketLoads, storageEfficiency, electricityLoadType, hydrogenLoadType } = asset;

  const load = 'load' in asset ? asset.load : [];

  // DISPLAY LOGIC
  let disabledMarket;

  const maxLoad = maxScenarioMarketLoads.find(s => s.scenarioID === scenarioID && s.marketID === market.marketID)?.maxLoad || 0;

  let loadValue;
  let loadValueOfOtherMarket;
  let optionsArray: number[] = [];
  if (!demo) {
    loadValue = load.find(loadItem => loadItem.marketID === market.marketID && loadItem.roundID === roundID)?.load || 0;
    loadValueOfOtherMarket = load.find(loadItem => loadItem.marketID === (market.marketID === 1 ? 2 : 1) && loadItem.roundID === roundID)?.load || 0; // Storage & Flexible

    if (storageEfficiency !== null && storageEfficiency !== 1 && loadValueOfOtherMarket > 0 && electricityLoadType === 'generator') {
      disabledMarket = true;
    } // Storage Types

    // Array to iterate over for SelectOptions

    if (flexible) {
      let remainingDemand = Math.min(maxLoad + round(scenarioTypeLimit * flexible), scenarioTypeLimit - loadValueOfOtherMarket);
      if (electricityLoadType === 'generator' && remainingDemand > 20) remainingDemand = 20;

      optionsArray = [...Array(Math.max(0, remainingDemand + 1))].map((a, i) => i);
    } else {
      optionsArray = [...Array(Math.max(0, maxLoad + 1))].map((a, i) => i);
    }
  }
  if (demo) {
    loadValue = maxLoad;
  }

  return (
    <tr>
      <th>
        <h5 className={styles.marketLabel}>{market.label} </h5>
      </th>
      <th className={styles.loadValue}>
        {demo ||
        (market.type === 'hydrogenMarket' && hydrogenLoadType === 'producer' && electricityLoadType === 'customer') ||
        (market.type === 'hydrogenMarket' && hydrogenLoadType === 'consumer' && electricityLoadType === 'generator') ? (
          <h5>{loadValue}</h5>
        ) : (
          <Select
            disabled={disabledMarket || !settings.allowAssetLoading || disabled}
            value={loadValue}
            size='small'
            listHeight={500}
            className={styles.loadSelect}
            onChange={option => {
              handleLoadChange({
                asset,
                value: option,
                market,
                setError,
                scenarioTypeLimit,
                roundID,
                markets
              });
            }}>
            {optionsArray.map(value => (
              <Option value={value} key={value} data-testid={`asset-select-load-${value}`}>
                {`${value}/${maxLoad}`}
              </Option>
            ))}
          </Select>
        )}
      </th>
      <th>
        <p>GWh</p>
      </th>
    </tr>
  );
});
