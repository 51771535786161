import { controlDataContext, gameProgressContext, ScenarioSelect, setErrorContext, setupDataContext, socket } from '@powertrader/core';
import { IAssetTypeAdjustment, IGameAction, ISharePriceEditingKey, ISharePriceFactors, isValidAdjustment } from '@powertrader/schema';
import { GameSettingInputType, SharePriceAdjustmentInterface, AssetTypeAdjustmentInterface } from '@powertrader/core';
import { memo, useContext, useEffect, useMemo, useState } from 'react';
import styles from './GameFlowAction.module.css';
import { DeleteOutlined, InfoCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';

interface IGameFlowAction {
  action: IGameAction;
  updateLiveActions: (action: IGameAction) => void;
}
export const GameFlowAction = memo(({ action, updateLiveActions }: IGameFlowAction) => {
  const { settingDetails, scenarioDefinition, settings } = useContext(setupDataContext);
  const { roundID } = useContext(gameProgressContext);
  const { sharePriceFactors } = useContext(controlDataContext);
  const { assetTypeDefinitions } = useContext(setupDataContext);
  const setError = useContext(setErrorContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const setting = action.entryID ? settingDetails.find(s => s.baseSettingsID === action.entryID && action.actionUpdateType === 'setting') : undefined;
  const [newValue, setNewValue] = useState(action.settingValue ?? '');
  const [actionParameters, setActionParameters] = useState(action.actionParameters);

  const sharePriceActionFactors =
    action.actionParameters && action.actionUpdateType === 'sharePriceFactor'
      ? sharePriceFactors.reduce((acc: ISharePriceFactors[], spf) => {
          const matchingSPF = (action.actionParameters as ISharePriceFactors[])?.find(spfa => spfa.baseLimitsID === spf.baseLimitsID);
          const result = acc;
          result.push({ ...spf, ...matchingSPF });
          console.log('matchingSPF', matchingSPF);
          if (matchingSPF) return result;

          return acc;
        }, [])
      : [];

  const [showParameterAdjustment, setShowParameterAdjustment] = useState(false);
  const [sharePriceActionFactorsState, setSharePriceActionFactorsState] = useState(sharePriceActionFactors);

  const showWarning = useMemo(() => {
    if (action.actionUpdateType === 'assetTypeAdjustment') {
      return !isValidAdjustment(actionParameters as IAssetTypeAdjustment, assetTypeDefinitions);
    }
    return false;
  }, [action.actionUpdateType, actionParameters, assetTypeDefinitions]);

  const label = () => {
    if (setting?.label) return setting.label;
    if (action.actionUpdateType === 'scenario') return 'Scenario';
    if (action.actionUpdateType === 'sharePriceFactor') return 'Share Price Factors';
    if (action.actionUpdateType === 'assetTypeAdjustment') return 'Asset Type Adjustment';
  };

  useEffect(() => {
    if (action.settingValue) setNewValue(action.settingValue);
  }, [action.settingValue]);

  /** Setting Switch Action Handlers */
  const handleSettingSwitchChange = () => {
    const updatedAction = { ...action };
    updatedAction.settingValue = !updatedAction.settingValue;

    updateLiveActions(updatedAction);
  };

  /** Setting Input Action Handlers */
  const handleSettingOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewValue(event.target.value);
  };

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (setting?.type === 'int' || setting?.type === 'float') {
      const value = isNaN(parseFloat(event.target.value)) ? 0 : parseFloat(event.target.value);
      const updatedAction = { ...action, settingValue: value };
      updateLiveActions(updatedAction);
      setNewValue(value);
    } else {
      setNewValue(event.target.value);
      const updatedAction = { ...action, settingValue: event.target.value };

      updateLiveActions(updatedAction);
    }
  };

  /** Scenario Action Handlers */
  const handleScenarioChange = (newScenarioValue: number) => {
    const updatedAction = { ...action, entryID: newScenarioValue };

    updateLiveActions(updatedAction);
  };

  /** Shareprice Action Handlers */
  const handleSharePriceSave = (editingKey: ISharePriceEditingKey, editingValue: string | undefined) => {
    if (editingKey.baseLimitsID === null || editingKey.key === null) {
      // message.error('Value not changed');
      return;
    }
    const newEditingValue = editingValue !== null && editingValue !== undefined ? parseFloat(editingValue) : 0;
    if (isNaN(newEditingValue)) {
      message.info('Share price factor must be a number');
      return;
    }
    if (action.actionParameters === null) {
      message.error('Cannot update share price factor - try refresh');
      return;
    }
    const updatedAction = { ...action };
    const index =
      updatedAction.actionUpdateType === 'sharePriceFactor'
        ? (updatedAction.actionParameters as ISharePriceFactors[])?.findIndex(spf => spf.baseLimitsID === editingKey.baseLimitsID)
        : -1;

    if (
      index !== -1 &&
      index !== undefined &&
      editingKey.key &&
      updatedAction.actionParameters &&
      updatedAction.actionUpdateType === 'sharePriceFactor'
    ) {
      (updatedAction.actionParameters as ISharePriceFactors[])[index][editingKey.key] = newEditingValue;
    }

    updateLiveActions(updatedAction);

    setSharePriceActionFactorsState(prevState => {
      const newState = [...prevState];
      const spIndex = newState.findIndex(spf => spf.baseLimitsID === editingKey.baseLimitsID);

      if (spIndex !== -1 && editingKey.key) newState[spIndex][editingKey.key] = newEditingValue;

      // console.log(newState);
      return newState;
    });
  };

  /** AssetType Action Handlers */
  const handleAssetTypeSave = (newAdjustment: IAssetTypeAdjustment) => {
    action.actionParameters = newAdjustment;
    updateLiveActions(action);
    setActionParameters(newAdjustment);
  };

  const handleDeleteAction = () => {
    socket.emit('removeGameAction', action, (E: Error | string, result?: string) => {
      if (result) return;
      if (E) {
        setError({ error: E, message: 'Error removing action' });
        return;
      }
    });
  };
  const isSetup = settings.currentGamePhase === 'setup' || settings.currentGamePhase === 'portfolio';
  return (
    <>
      <div className={styles.container}>
        <div className={styles.actionContainer} style={showWarning ? { borderColor: 'red' } : {}}>
          <div className={styles.topRow}>
            <h3>{label()}</h3>
            <div className={styles.inputArea}>
              {setting && (
                <GameSettingInputType
                  disabled={!isSetup && action.triggerRoundID <= roundID}
                  setting={{ ...setting, value: action.settingValue ?? '' }}
                  newValue={setting.type === 'boolean' ? +(action.settingValue ?? 0) : newValue}
                  handleOptionChange={handleSettingOptionChange}
                  handleSwitchChange={handleSettingSwitchChange}
                  onBlur={handleInputBlur}
                />
              )}
              {action.entryID && action.actionUpdateType === 'scenario' && (
                <div className={styles.scenarioSelectContainer}>
                  <ScenarioSelect
                    disabled={!isSetup && action.triggerRoundID <= roundID}
                    scenario={action.entryID ?? undefined}
                    handleScenarioChange={handleScenarioChange}
                  />
                  <InfoCircleOutlined onClick={() => setIsModalVisible(true)} className={styles.info} />
                </div>
              )}
              {action.actionParameters && (
                <RightOutlined
                  rotate={showParameterAdjustment ? 90 : 0}
                  onClick={() => setShowParameterAdjustment(prevState => !prevState)}
                  className={styles.actionParameterArrow}
                />
              )}
            </div>
            {action.actionUpdateType !== 'scenario' && (
              <>
                {(settings.currentGamePhase === 'setup' || action.triggerRoundID > roundID) && (
                  <DeleteOutlined onClick={() => handleDeleteAction()} className={styles.delete} />
                )}
              </>
            )}
          </div>
          <div>
            {action.actionUpdateType === 'sharePriceFactor' && showParameterAdjustment && (
              <div className={styles.sharePriceContainer}>
                <SharePriceAdjustmentInterface
                  disabled={!isSetup && action.triggerRoundID <= roundID}
                  sharePriceFactors={sharePriceActionFactorsState}
                  handleSave={handleSharePriceSave}
                />
              </div>
            )}
            {action.actionUpdateType === 'assetTypeAdjustment' && showParameterAdjustment && (
              <AssetTypeAdjustmentInterface
                disabled={!isSetup && action.triggerRoundID <= roundID}
                assetTypeAdjustment={action.actionParameters as IAssetTypeAdjustment}
                handleSave={handleAssetTypeSave}
              />
            )}
          </div>
        </div>
      </div>

      <Modal
        title='Scenario Descriptions'
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key='submit' type='primary' onClick={() => setIsModalVisible(false)}>
            Thanks
          </Button>
        ]}>
        {scenarioDefinition.map(sd => (
          <div key={sd.scenarioID} className={styles.modalInfo}>
            <h4>{sd.label}</h4>
            <p>{sd.description}</p>
          </div>
        ))}
      </Modal>
    </>
  );
});
