import {
  TeamDetailedView,
  TeamSummaryCard,
  TradeTable,
  GameSettingsControl,
  AuctionControl,
  Timer,
  DisplayController,
  RoundControl,
  RoundScenarioControl,
  SharePriceControl,
  ActiveAuctionDisplay,
  openTradesContext,
  gameProgressContext,
  setupDataContext,
  openTradesCompletedContext,
  bilateralTradesContext,
  PasscodeModal,
  TradeDetailsTable
} from '@powertrader/core';
import { BaseTeam, IAsset, IControlDisplay, ITeamRoundData, ITeamScore, OpenTrade } from '@powertrader/schema';
import { Button, Popconfirm } from 'antd';
import { Dispatch, memo, SetStateAction, useContext, useState } from 'react';
import styles from './ControlView.module.css';

interface IControlView {
  selectedTeamScore: ITeamScore;
  allAssets: IAsset[];
  selectedTeam: BaseTeam;
  selectedTeamRoundData: ITeamRoundData | undefined;
  systemStats: {
    generation: number;
    minDemand: number;
  };
  selectedTeamID: number;
  setSelectedTeamID: Dispatch<SetStateAction<number>>;
  teamScores: ITeamScore[];
  setControlDisplay: IControlDisplay['setControlDisplay'];
}
export const ControlView = memo(
  ({
    selectedTeamScore,
    allAssets,
    selectedTeam,
    selectedTeamRoundData,
    selectedTeamID,
    setSelectedTeamID,
    systemStats,

    teamScores,

    setControlDisplay
  }: IControlView) => {
    const { settings, user, markets } = useContext(setupDataContext);
    const { roundID } = useContext(gameProgressContext);
    const completedTrades = useContext(openTradesCompletedContext);
    const bilateralTrades = useContext(bilateralTradesContext);

    const openTrades = useContext(openTradesContext);
    const [isPasscodeModalVisible, setIsPasscodeModalVisible] = useState(false);
    return (
      <div className={styles.controlAreaInner}>
        <div className={styles.leftControlColumns}>
          <div className={styles.leftTwoControlColumns}>
            <div className={styles.controlColumn}>
              <TeamDetailedView
                teamScore={selectedTeamScore}
                allAssets={allAssets}
                selectedTeam={selectedTeam}
                teamRoundData={selectedTeamRoundData}
                teamCompletedTrades={completedTrades.filter(
                  ct => ct.openTrade.offeringTeam.teamID === selectedTeamID || ct.receivingTeam.teamID === selectedTeamID
                )}
                teamCompletedBilateralTrades={bilateralTrades.filter(
                  ct => ct.offeringTeam.teamID === selectedTeamID || ct?.receivingTeam?.teamID === selectedTeamID
                )}
              />
              <div className={styles.systemStats}>
                <Popconfirm title='Are you screen sharing?' onConfirm={() => setIsPasscodeModalVisible(prev => !prev)} okText='No' cancelText='Yes'>
                  <Button ghost size='small' shape='round'>
                    Passcodes
                  </Button>
                </Popconfirm>
                <PasscodeModal
                  isPasscodeModalVisible={isPasscodeModalVisible}
                  setIsPasscodeModalVisible={setIsPasscodeModalVisible}
                  noOfTeams={settings.noOfTeams}
                />
                <h4>System Availability: {systemStats.generation} GWh</h4>
                <h4>System Min Demand: {systemStats.minDemand} GWh</h4>
              </div>
              <TeamSummaryCard
                selectedTeamID={selectedTeamID}
                setSelectedTeamID={setSelectedTeamID}
                noOfTeams={settings.noOfTeams}
                teamScores={teamScores}
              />
            </div>
            <div className={styles.controlColumn}>
              <GameSettingsControl />

              <div className={styles.logoContainer}>
                <h2>Power Trader</h2>

                <img className={styles.logo} alt='Heuristic Logo' src='/logo.png' />
              </div>
              <AuctionControl allAssets={allAssets} />
            </div>
          </div>
          <div className={styles.settingContainer}>
            <div className='componentHeader'>
              <h3>Open Market</h3>
            </div>
            <div className={styles.tradeTablesContainer}>
              {markets.map(market => {
                const dealTypes: OpenTrade['dealType'][] = ['sell', 'buy'];
                return (
                  <div key={market.marketID} className={styles.tradesTable}>
                    <h3 className={styles.tradeMarketHeading}>{market.label} Market</h3>
                    {dealTypes.map(dealType => (
                      <TradeTable
                        key={dealType}
                        dealType={dealType}
                        trades={openTrades.filter(trade => trade.volumeRemaining > 0 && trade.market.marketID === market.marketID)}
                        market={market}
                        teamID={user.teamID}
                        tradeType={market.name === 'carbon' ? 'carbonMarket' : market.name === 'hydrogen' ? 'hydrogenMarket' : 'powerExchange'}
                        controlView
                      />
                    ))}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.settingContainer}>
            <div className='componentHeader'>
              <h3>Bilateral Trades</h3>
            </div>

            <TradeDetailsTable />
          </div>
        </div>
        <div className={styles.lastControlColumn}>
          <div className={styles.timmerControls}>
            <Timer endTime={settings.timerEndTime} control />
            <DisplayController onClick={setControlDisplay} round={roundID} />
          </div>
          <RoundControl teamScores={teamScores} />
          <RoundScenarioControl />
          <SharePriceControl />
          <ActiveAuctionDisplay />
        </div>
      </div>
    );
  }
);
