import { useContext, memo, SetStateAction, Dispatch } from 'react';
import { ISetupData, IGameProgress, ITeamRoundData, ITeamScore, IAsset, BaseTeam } from '@powertrader/schema';

import { round } from 'lodash';

import { setupDataContext, gameProgressContext } from '../../../context';

import styles from './TeamSummaryCard.module.css';
import { SummaryCardCell } from './SummaryCardCell';
import { SummaryCardTeamName } from './SummaryCardTeamName';

interface ITeamSummaryCardRow {
  team: BaseTeam;
  teamScore: ITeamScore;
  setSelectedTeamID: Dispatch<SetStateAction<number>>;
  isSelectedTeam: boolean;
  genAssets: IAsset[];
  strategyStatement: ITeamRoundData['strategyStatement'];
  strapline: ITeamRoundData['strapline'];
  strategySubmitted: boolean;
}

export const TeamSummaryCardRow = memo(
  ({ team, teamScore, setSelectedTeamID, isSelectedTeam, genAssets, strategyStatement, strapline, strategySubmitted }: ITeamSummaryCardRow) => {
    const { settings } = useContext<ISetupData>(setupDataContext);
    const { scenarioID } = useContext<IGameProgress>(gameProgressContext);

    let maxPotentialGen = 0;
    for (const asset of genAssets) {
      maxPotentialGen += asset.typeScenarioLimits.find(l => l.scenarioID === scenarioID)?.energyMarketLimit || 0;
    }

    const unusedGeneration = maxPotentialGen - teamScore.totals.generation;

    return (
      <>
        <div
          data-testid={`team-summary-card-row-${team.teamID}`}
          className={styles.innerCard}
          style={isSelectedTeam ? { border: '2px solid white', borderRadius: '10px' } : { border: '1px solid black', borderRadius: '10px' }}
          onClick={() => {
            setSelectedTeamID(team.teamID);
          }}>
          <div className={styles.summaryContainer}>
            <SummaryCardTeamName team={team} strapline={strapline} strategyStatement={strategyStatement} strategySubmitted={strategySubmitted} />
            {teamScore.wholesaleBalance.map((wb, i) => (
              <SummaryCardCell key={i} colorToDisplay={{ value: round(wb.balance), greenLimit: 0, redLimit: -2 }}>
                {round(wb.balance)}
              </SummaryCardCell>
            ))}
            <SummaryCardCell colorToDisplay={{ value: teamScore.hydrogen.currentStored, greenLimit: 0, redLimit: 3 }}>
              {teamScore.hydrogen.currentStored}
            </SummaryCardCell>
            <SummaryCardCell colorToDisplay={{ value: round(teamScore.customerTotalShortR), greenLimit: 0, redLimit: 3 }} style={{ width: '12%' }}>
              {round(teamScore.customerTotalShortR)}
            </SummaryCardCell>
            <SummaryCardCell colorToDisplay={{ value: unusedGeneration, greenLimit: 0, redLimit: 100 }} style={{ width: '13%' }}>
              <>
                {teamScore.totals.generation} / {maxPotentialGen}
              </>
            </SummaryCardCell>
            <SummaryCardCell colorToDisplay={{ value: round(teamScore.totals.profit), greenLimit: 0, redLimit: -100 }}>
              <>
                {settings.currency} {round(teamScore.totals.profit)}k
              </>
            </SummaryCardCell>
            <SummaryCardCell colorToDisplay={{ value: round(teamScore.totals.carbonCertificates, 2), greenLimit: 0, redLimit: -4 }}>
              <>
                {round(teamScore.totals.carbonCertificates, 2)} kt.CO<sub>2</sub>
              </>
            </SummaryCardCell>
          </div>
        </div>
      </>
    );
  }
);
