import { ITeamScore } from '@powertrader/schema';
import { createContext, Dispatch, FunctionComponent, SetStateAction, useContext, useState } from 'react';

const hydrogenDefaultValues: ITeamScore['hydrogen'] = {
  initialStored: 0,
  currentStored: 0,
  changeInHydrogenStorage: 0,
  produced: 0,
  consumed: 0,
  trading: 0,
  wholesaleBalance: 0,
  minDemand: 0,
  unfulfilled: 0,
  capacity: 0
};

interface IHydrogenValuesContext {
  hydrogenValues: ITeamScore['hydrogen'];
  setHydrogenValues: Dispatch<SetStateAction<IHydrogenValuesContext['hydrogenValues']>>;
}
const HydrogenValuesContext = createContext<IHydrogenValuesContext>({
  hydrogenValues: hydrogenDefaultValues,
  setHydrogenValues: () => {}
});

export const HydrogenValuesProvider = ({ children }: { children: React.ReactNode }) => {
  const [hydrogenValues, setHydrogenValues] = useState<ITeamScore['hydrogen']>(hydrogenDefaultValues);

  return <HydrogenValuesContext.Provider value={{ hydrogenValues, setHydrogenValues }}>{children}</HydrogenValuesContext.Provider>;
};

export const useHydrogenValues = () => useContext(HydrogenValuesContext);
