import { Asset, setupDataContext } from '@powertrader/core';
import { IAsset } from '@powertrader/schema';
import { memo, useContext, useEffect, useState } from 'react';
import styles from './CentreStage.module.css';

export const CSAuctionDisplay = memo(() => {
  const { assets } = useContext(setupDataContext);
  const [auctionAssets, setAuctionAssets] = useState<IAsset[]>([]);
  useEffect(() => {
    setAuctionAssets(
      assets.filter(asset => asset.inAuction === 1) // hides assets already taken
    );
  }, [assets]);

  const sellNext = auctionAssets.filter(asset => !!asset.sellNext);
  return (
    <>
      <h2 className={styles.sectionTitle}>Auction Screen</h2>
      {auctionAssets.length === 0 ? (
        <h3 style={{ marginTop: '50px' }} className={styles.auctionHeadings}>
          Auction Completed
        </h3>
      ) : (
        <>
          <div className={styles.auctionOnSaleNext}>
            {sellNext.length === 0 ? (
              <h3 className={styles.auctionHeadings}>Waiting for auction to start</h3>
            ) : (
              <h3 className={styles.auctionHeadings}>On Sale Now!</h3>
            )}
            {sellNext.map(asset => (
              <>
                <Asset demo details='show' asset={asset} key={asset.assetID} />
              </>
            ))}
          </div>
          <h3 className={styles.auctionHeadings}>Assets in Auction</h3>
          <div className={styles.auctionComingUp}>
            {auctionAssets
              .filter(asset => !asset.sellNext)
              .map(asset => (
                <>
                  <Asset demo details='show' asset={asset} key={asset.assetID} />
                </>
              ))}
          </div>
        </>
      )}
    </>
  );
});
