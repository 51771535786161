import type { IBooleanModalProps } from '@powertrader/schema';
import { Button, Modal } from 'antd';
import { memo } from 'react';

interface ITradingHelpModal {
  showTradingHelpModal: IBooleanModalProps['showModal'];
  setShowTradingHelpModal: IBooleanModalProps['setShowModal'];
}
export const TradingModal = memo(({ showTradingHelpModal, setShowTradingHelpModal }: ITradingHelpModal) => {
  return (
    <Modal
      width='fit-content'
      open={showTradingHelpModal}
      onCancel={() => setShowTradingHelpModal(false)}
      footer={[
        <Button key='submit' type='primary' onClick={() => setShowTradingHelpModal(false)}>
          Ok
        </Button>
      ]}>
      <div style={{ textAlign: 'center' }}>
        <h2>Watch this short video for a trading demonstration</h2>
        <p style={{ fontSize: '16px', marginBottom: '10px' }}>If you would like further help, please message the Heuristic team in the chat.</p>
        <iframe
          width='880'
          height='520'
          src='https://www.youtube.com/embed/f2QEfZfAfU0'
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      </div>
    </Modal>
  );
});
