import { CheckOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { BaseTeam } from '@powertrader/schema';
import { Tooltip } from 'antd';
import { memo } from 'react';
import styles from './TeamSummaryCard.module.css';

interface ISummaryCardTeamName {
  team: BaseTeam;
  strapline: string;
  strategyStatement: string;
  strategySubmitted: boolean;
}
export const SummaryCardTeamName = memo(({ team, strapline, strategyStatement, strategySubmitted }: ISummaryCardTeamName) => {
  return (
    <Tooltip
      placement='right'
      title={
        <>
          <strong>Strapline: </strong>
          {strapline}

          <br />
          <strong>Strategy Statement</strong>
          <br />
          {strategyStatement}
        </>
      }>
      <div
        style={{
          backgroundColor: team.color,
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px'
        }}
        className={styles.title}>
        {' '}
        <h4>{team.label}</h4>
        {strategySubmitted && team.type === 'PLAYER' && <CheckOutlined data-testid='strategy-submitted-icon' />}
        {team.type === 'DEMO' && <EyeInvisibleOutlined data-testid='demo-team-active-icon' />}
      </div>
    </Tooltip>
  );
});
