import { Asset } from '@powertrader/core';
import type { IAdditionalInformationDisplayProps, IAsset, IAssetListDisplayProps } from '@powertrader/schema';
import { Button } from 'antd';
import styles from './AssetList.module.css';

interface IAssetList {
  assets: IAsset[];
  label: string;
  className: string;
  setAdditionalInformationDisplay?: IAdditionalInformationDisplayProps['setState'];
  setAssetListDisplay?: IAssetListDisplayProps['setState'];
}

export const AssetList = ({ assets, label, className, setAdditionalInformationDisplay, setAssetListDisplay }: IAssetList) => {
  return (
    <div className={className}>
      <h2 className={styles.sectionTitle}>{label}</h2>
      {setAssetListDisplay && (
        <Button
          ghost
          type='default'
          shape='round'
          size='small'
          onClick={() => setAssetListDisplay(prevState => (prevState === 'electricity' ? 'comodities' : 'electricity'))}>
          Toggle View
        </Button>
      )}
      {setAdditionalInformationDisplay && (
        <Button
          ghost
          type='default'
          shape='round'
          size='small'
          onClick={() => setAdditionalInformationDisplay(prevState => (prevState === 'upgradeList' ? undefined : 'upgradeList'))}>
          View Upgrade Options
        </Button>
      )}
      {assets.length === 0 ? (
        <h5 className={styles.noAssets}>No Assets to Display</h5>
      ) : (
        assets.map(asset => <Asset asset={asset} key={asset.assetID} />)
      )}
    </div>
  );
};
