import { Market, OpenTradeComplete } from '@powertrader/schema';
import { sumBy } from 'lodash';

export const tradeWeightedAverage = ({
  allTrades,
  roundID,
  marketType,
  shortfallPrice,
  buybackPrice
}: {
  allTrades: OpenTradeComplete[];
  roundID: number;
  marketType: Market['type'];
  shortfallPrice: number;
  buybackPrice: number;
}) => {
  const thisRoundAllTrades = allTrades.filter(
    t => t.openTrade.startRoundID <= roundID && t.openTrade.endRoundID >= roundID && t.openTrade.market.type === marketType
  );

  if (!thisRoundAllTrades.length) {
    return (shortfallPrice + buybackPrice) / 2;
  }

  const totalVol = sumBy(thisRoundAllTrades, a => a.volume);

  const sumProduct = thisRoundAllTrades.reduce((acc, trade) => acc + trade.openTrade.price * trade.volume, 0) / totalVol;

  return sumProduct;
};
