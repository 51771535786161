import { Button, Input } from 'antd';

import { useState, memo, useEffect, useContext } from 'react';
import { IErrorProps } from '@powertrader/schema';
import { socket } from '../../socket/socket';
import { setErrorContext } from '../../context';
import styles from './Timer.module.css';

const timeLeftFn = (endTime?: string) => {
  if (!endTime) return { actual: 0, minutes: null, seconds: null };
  const timeNow = new Date().getTime();
  const endTimeInLocal = new Date(endTime).getTime();
  const timeLeft = endTimeInLocal - timeNow;

  return { actual: timeLeft, minutes: Math.floor(timeLeft / 1000 / 60), seconds: Math.floor((timeLeft / 1000) % 60) };
};
interface ITimer {
  endTime: string | undefined;
  control?: boolean;
  onTimeEnded?: () => void;
}
export const Timer = memo(({ endTime, control = false, onTimeEnded }: ITimer) => {
  // endTime = Wed Feb 03 2021 18:21:06 GMT+0000 (Greenwich Mean Time)

  const setError = useContext(setErrorContext);

  const [minutes, setMinutes] = useState<number | null>(timeLeftFn(endTime).minutes);
  const [seconds, setSeconds] = useState<number | null>(timeLeftFn(endTime).seconds);
  const [timeAdded, setTimeAdded] = useState<number | ''>('');
  const [clockRunning, setClockRunning] = useState(false);

  useEffect(() => {
    setClockRunning(true);
  }, [endTime]);

  useEffect(() => {
    if (clockRunning && endTime) {
      const myInterval = setInterval(() => {
        const timeLeft = timeLeftFn(endTime);
        setMinutes(timeLeft.minutes);
        setSeconds(timeLeft.seconds);

        if (timeLeft.actual > 0) {
          setClockRunning(true);
        } else {
          if (onTimeEnded) onTimeEnded();
          setClockRunning(false);
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [clockRunning, endTime, onTimeEnded]);

  const handleAddTime = () => {
    socket.emit('addTimeToCountdown', timeAdded, (error: IErrorProps['error'], data: 'complete') => {
      if (error) {
        setError({
          message: `Cannot add time to timmer`,
          error
        });
      } else if (data === 'complete') {
        setTimeAdded('');
        setClockRunning(true);
        const log = `${timeAdded} minutes was added to the timer`;
        socket.emit('eventLog', {
          type: 'system',
          log
        });
      }
    });
  };

  const handleResetTime = () => {
    socket.emit('addTimeToCountdown', -9999999, (error: IErrorProps['error'], data: 'complete') => {
      if (error) {
        setError({
          message: `Cannot add time to timmer`,
          error
        });
      } else if (data === 'complete') {
        setTimeAdded('');
        const log = `${timeAdded} minutes was added to the timer`;
        socket.emit('eventLog', {
          type: 'system',
          log
        });
      }
    });
  };

  const isTimeUp = () => {
    if (!endTime) return null;
    if (clockRunning && ((seconds !== null && seconds >= 0) || (minutes !== null && minutes >= 0))) {
      return false;
    }

    return true;
  };

  const isTimeUpBol = isTimeUp();

  return (
    <div
      data-testid='game-timer'
      style={
        !endTime && !control
          ? { opacity: 0 }
          : isTimeUpBol || (seconds !== null && seconds < 10 && minutes === 0)
          ? { backgroundColor: 'red' }
          : seconds !== null && seconds < 30 && minutes === 0
          ? { backgroundColor: 'orange' }
          : {}
      }
      className={styles.innerCard}>
      <>
        {isTimeUpBol ? (
          <h3 style={{ color: 'white' }}>Times Up!</h3>
        ) : (
          <h2>
            {minutes}:{seconds !== null && seconds < 10 ? `0${seconds}` : seconds}
          </h2>
        )}
      </>

      {control && (
        <>
          <Input
            type='text'
            pattern='^[0-9]*'
            onChange={event => {
              if (event.target.validity.valid && +event.target.value > 0) {
                setTimeAdded(+event.target.value);
              } else {
                setTimeAdded('');
              }
            }}
            value={timeAdded}
            suffix='mins'
            className={styles.timeInput}
          />
          <div className={styles.footer}>
            {clockRunning && (
              <Button size='small' shape='round' type='primary' onClick={handleResetTime}>
                Reset
              </Button>
            )}
            <Button size='small' shape='round' type='primary' onClick={handleAddTime}>
              {clockRunning ? 'Add' : 'Start'}
            </Button>
          </div>
        </>
      )}
    </div>
  );
});
