import styles from './CentreStage.module.css';

export const CSWelcome = () => {
  return (
    <div className={styles.centerWrapper}>
      <h1 style={{ marginTop: '10%' }} className={styles.sectionTitle}>
        Welcome to Power Trader
      </h1>
      <div className={styles.logoContainer}>
        <img className={styles.logo} alt='Heuristic Logo' src='/logo.png' />
      </div>
      <h4 style={{ marginTop: '20px' }} className={styles.sectionTitle}>
        This demonstration will help you understand the basics of the game. <br />
        <br />
        On the left you have generators and on the right you have customers.
        <br />
        <br />
        Set the amount you would like to supply to your customers on the right, <br />
        and then try and increase your generation to meet this demand.
      </h4>
    </div>
  );
};
