import { setupDataContext } from '@powertrader/core';
import { useContext } from 'react';
import { CSSingleHeading } from '../GamePlayArea/CentreStage/CSSingleHeading';
import styles from './MobileView.module.css';
import { BilateralScreen } from './BilateralTrading';

export const MobileView = () => {
  const { settings } = useContext(setupDataContext);

  const bilateralsEnabled =
    settings.allowMobileBilaterals &&
    ((settings.currentGamePhase === 'strategy' && settings.allowHedging) ||
      (settings.currentGamePhase === 'operational' && settings.allowBilaterals));
  return bilateralsEnabled ? (
    <BilateralScreen />
  ) : (
    <div className={styles.defaultContainer}>
      <CSSingleHeading text='Welcome to Power Trader' logo />
      <h3>There are currently no mobile actions to perform, continue game play on desktop</h3>
    </div>
  );
};
