import { Button, Input, message, Popconfirm, Tooltip } from 'antd';
import { controlDataContext, setErrorContext, socket } from '@powertrader/core';
import { memo, useCallback, useContext, useState } from 'react';
import styles from './GameFlowPreset.module.css';
import { DeleteOutlined, ReloadOutlined, SaveOutlined } from '@ant-design/icons';
import { GamePreset } from '@powertrader/schema';

export const GameFlowPreset = memo(() => {
  const setError = useContext(setErrorContext);
  const { gamePresets } = useContext(controlDataContext);
  const [newPresetName, setNewPresetName] = useState<string>('');

  const handleAddPreset = useCallback(() => {
    const upperCase = newPresetName.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    socket.emit('addNewGamePreset', upperCase, (E: Error | string, result?: string) => {
      if (result === 'complete') {
        setNewPresetName('');
        message.success('Preset Added');
        return;
      }

      if (E) {
        setError({ error: E, message: 'Failed to add game preset' });
        return;
      }
    });
  }, [newPresetName, setError]);

  const handleLoadPreset = useCallback(
    (preset: GamePreset) => {
      socket.emit('loadGamePreset', preset, (E: Error | string, result?: string) => {
        if (result === 'complete') {
          message.success(`Preset "${preset.label}" successfully loaded`);
          return;
        }

        if (E) {
          setError({ error: E, message: 'Failed to load game preset' });
          return;
        }
      });
    },
    [setError]
  );
  const handleSavePreset = useCallback(
    (preset: GamePreset) => {
      socket.emit('updateGamePreset', preset, (E: Error | string, result?: string) => {
        if (result === 'complete') {
          message.success('Preset Updated');
          return;
        }

        if (E) {
          setError({ error: E, message: 'Failed to update game preset' });
          return;
        }
      });
    },
    [setError]
  );
  const handleDeletePreset = useCallback(
    (preset: GamePreset) => {
      socket.emit('removeGamePreset', preset, (E: Error | string, result?: string) => {
        if (result === 'complete') {
          message.success('Preset Removed');
          return;
        }

        if (E) {
          setError({ error: E, message: 'Failed to remove game preset' });
          return;
        }
      });
    },
    [setError]
  );

  return (
    <div className={styles.presetInnerCard}>
      <h3>Game Presets</h3>
      <div className={styles.selectAPreset}>
        <Input
          value={newPresetName}
          onChange={e => setNewPresetName(e.target.value)}
          placeholder='Preset Name'
          className={styles.input}
          onPressEnter={() => handleAddPreset()}
          maxLength={40}
        />
        <Button
          shape='round'
          type='primary'
          className={styles.button}
          onClick={() => {
            handleAddPreset();
          }}>
          Add New Preset
        </Button>
      </div>

      <div className={styles.listOfPresets}>
        {gamePresets.map(preset => (
          <div key={preset.id} className={styles.presetNameContainer}>
            <Tooltip title={preset.noOfRounds + ' Rounds'}>
              <h4 key={preset.id}>{preset.label}</h4>
            </Tooltip>

            <div className={styles.presetButtons}>
              <Popconfirm
                title={`Are you sure? Unsaved game configuration will be lost`}
                placement='top'
                onConfirm={() => handleLoadPreset(preset)}
                okText='Yes'
                cancelText='No'>
                <Button shape='round' size='small' type='primary' ghost className={styles.presetButton} icon={<ReloadOutlined />} />
              </Popconfirm>
              <Popconfirm
                title={`Are you sure? Preset will be overwritten`}
                placement='top'
                onConfirm={() => handleSavePreset(preset)}
                okText='Yes'
                cancelText='No'>
                {' '}
                <Button shape='round' size='small' type='primary' ghost className={styles.presetButton} icon={<SaveOutlined />} />
              </Popconfirm>
              <Popconfirm title={`Are you sure?`} placement='top' onConfirm={() => handleDeletePreset(preset)} okText='Yes' cancelText='No'>
                {' '}
                <Button shape='round' size='small' type='primary' ghost className={styles.presetButton} icon={<DeleteOutlined />} />
              </Popconfirm>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});
