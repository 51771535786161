import { INewTradeReq } from '@powertrader/schema';
import { message } from 'antd';

export const decodeToken = (code: string, setCode: React.Dispatch<React.SetStateAction<string | null | undefined>>) => {
  const market = Number(code?.[0]);
  const volume = Number(code?.[1]);

  if (!(Number(market) >= 1 && Number(market) <= 4) || isNaN(Number(volume))) {
    message.error('Invalid token');
    setCode(null);
    return;
  }

  const tradeType: INewTradeReq['tradeType'] = market === 1 || market === 2 ? 'powerExchange' : market === 3 ? 'hydrogenMarket' : 'carbonMarket';
  return { tradeType, marketID: market, volume };
};
