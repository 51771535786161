import { useState, useContext, memo, useMemo } from 'react';

import { Button, message, Popconfirm, Select } from 'antd';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { GamePreset, IErrorProps, ISettingsDetails } from '@powertrader/schema';

import { socket } from '../../../socket/socket';
import { controlDataContext, setErrorContext, setupDataContext } from '../../../context';

import styles from './GameSettingsControl.module.css';
import { GameSettingInputType } from './GameSettingInputType';
const { Option } = Select;
export const GameSettingsControl = memo(() => {
  const { settingDetails, settings } = useContext(setupDataContext);
  const { gamePresets } = useContext(controlDataContext);
  const setError = useContext(setErrorContext);
  const [locked, setLocked] = useState(settings.currentGamePhase !== 'setup');
  const [gamePreset, setGamePreset] = useState<GamePreset['id'] | undefined>();
  const liveSettings = useMemo(() => {
    if (settings.newGame) return settingDetails.filter(s => s.changeable === 'setup');
    if (settings.currentGamePhase === 'setup') return settingDetails.filter(s => s.changeable === 'any' || s.changeable === 'setup');
    if (settings.currentGamePhase === 'demo')
      return settingDetails.filter(s => s.changeable === 'any' || s.changeable === 'during' || s.changeable === 'demo');

    return settingDetails.filter(s => s.changeable === 'any' || s.changeable === 'during');
  }, [settingDetails, settings.currentGamePhase, settings.newGame]);

  const [newChangeableSettings, setNewChangeableSettings] = useState<ISettingsDetails[]>([]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>, setting: ISettingsDetails) => {
    const { baseSettingsID } = setting;

    if (setting.type === 'int' || setting.type === 'float') {
      let valueToSet;

      if (event.target.validity.valid) {
        valueToSet = event.target.value;

        const tempArray = [...newChangeableSettings];
        let found = false;
        for (const newSetting of tempArray) {
          if (newSetting.baseSettingsID === baseSettingsID) {
            newSetting.value = valueToSet;
            found = true;
            break;
          }
        }
        if (!found) {
          tempArray.push({ ...setting, value: valueToSet });
        }
        setNewChangeableSettings(tempArray);
      }
    } else if (setting.type === 'string') {
      const valueToSet = event.target.value;

      const tempArray = [...newChangeableSettings];
      let found = false;
      for (const newSetting of tempArray) {
        if (newSetting.baseSettingsID === baseSettingsID) {
          newSetting.value = valueToSet;
          found = true;
          break;
        }
      }
      if (!found) {
        tempArray.push({ ...setting, value: valueToSet });
      }
      setNewChangeableSettings(tempArray);
    }
  };
  const handleLock = () => {
    if (!locked) {
      // on Lock

      setNewChangeableSettings([]);
    }
    setLocked(prevState => !prevState);
  };
  const handleSwitchChange = (setting: ISettingsDetails, value: boolean) => {
    const newState = setting;
    newState.value = value ? 1 : 0;
    socket.emit('updateSettings', [newState], (error: IErrorProps['error'], data: 'complete') => {
      if (error) {
        setError({
          message: `Failed saving settings`,
          error
        });
      } else if (data === 'complete') {
        message.success(`${setting.label} set to ${value}`);
        socket.emit('eventLog', {
          type: 'system',
          log: `Setting saved`
        });
      }
    });
  };

  const handleSave = () => {
    if (settings.newGame) {
      if (!gamePreset) {
        message.error('Select a game preset, you can change this later');
        return;
      }
      const preset = gamePresets.find(p => p.id === gamePreset);
      if (!preset) throw Error('Error getting preset');
      socket.emit('loadGamePreset', preset, (E: Error | string, result?: string) => {
        if (result === 'complete') {
          message.success(`Preset "${preset.label}" successfully loaded`);
          return;
        }

        if (E) {
          setError({ error: E, message: 'Failed to load game preset' });
          return;
        }
      });

      const newGameSetting = settingDetails.find(s => s.name === 'newGame');
      if (newGameSetting) {
        newGameSetting.value = 0;
        newChangeableSettings.push(newGameSetting);
      }
    }
    // check is inputs are valid

    for (const setting of newChangeableSettings) {
      if (setting.type === 'int' || setting.type === 'float') {
        setting.value = +setting.value ? +setting.value : 0;
      }
    }
    if (newChangeableSettings.length > 0) {
      socket.emit('updateSettings', newChangeableSettings, function (error: IErrorProps['error'], data: 'complete') {
        if (error) {
          setError({
            message: `Failed saving settings`,
            error
          });
          handleLock();
        } else if (data === 'complete') {
          message.success(`Game Settings Updated`);
          socket.emit('eventLog', {
            type: 'system',
            log: `Setting saved ${newChangeableSettings.map(s => `${s.label}: ${s.value},`)}`
          });
          handleLock();
        }
      });
    } else {
      message.warning('No settings were change');
      handleLock();
    }
  };

  const handleNewGame = () => {
    socket.emit('startNewGame', function (error: IErrorProps['error'], data: 'complete') {
      if (error) {
        setError({
          message: `Failed starting new game`,
          error
        });
      } else if (data === 'complete') {
        message.success(`New Game Started`);
        socket.emit('eventLog', {
          type: 'system',
          log: `New Game Started`
        });
      }
    });
  };

  return (
    <div data-testid='game-settings' className={styles.innerCard}>
      <div className='componentHeader'>
        <h3>Game Settings</h3>
      </div>
      <div className={styles.settings}>
        {liveSettings.map((setting, i) => {
          let newValue;
          if (newChangeableSettings.find(ncs => ncs.baseSettingsID === setting.baseSettingsID)?.value !== undefined) {
            newValue = newChangeableSettings.find(ncs => ncs.baseSettingsID === setting.baseSettingsID)?.value;
          } else {
            newValue = liveSettings[i].value;
          }

          return (
            <div key={setting.baseSettingsID} className={styles.optionContainer}>
              <h5 className={styles.name}> {setting.label}:</h5>
              <GameSettingInputType
                setting={setting}
                newValue={newValue}
                locked={locked}
                handleOptionChange={handleOptionChange}
                handleSwitchChange={handleSwitchChange}
              />
            </div>
          );
        })}
      </div>
      {settings.newGame ? (
        <div className={styles.footer}>
          <Select
            data-testid='game-preset'
            showSearch
            placeholder='Select a Game Preset'
            optionFilterProp='children'
            style={{ width: '190px' }}
            value={gamePreset}
            onChange={option => setGamePreset(option)}>
            {gamePresets.map(preset => (
              <Option key={preset.id} value={preset.id}>
                {preset.label + ' (' + preset.noOfRounds + "R's)"}
              </Option>
            ))}
          </Select>
          <Button
            data-testid='setup-game'
            disabled={locked}
            type='primary'
            size='small'
            shape='round'
            onClick={handleSave}
            className={styles.saveButton}>
            Setup Game
          </Button>
        </div>
      ) : (
        <div className={styles.footer}>
          <Button
            data-testid='save-game-settings'
            disabled={locked}
            type='primary'
            size='small'
            shape='round'
            onClick={handleSave}
            className={styles.saveButton}>
            Save Setting
          </Button>

          {locked ? (
            <LockOutlined data-testid='lock' className={styles.locker} onClick={() => handleLock()} />
          ) : (
            <UnlockOutlined className={styles.locker} onClick={() => handleLock()} />
          )}
          <Popconfirm
            data-testid='start-new-game-confirm'
            title='Are you absolutely sure? This will clear ALL current game and chat data'
            placement='bottomRight'
            onConfirm={handleNewGame}
            okText='Yes'
            cancelText='No'>
            <Button data-testid='start-new-game' disabled={locked} type='primary' size='small' className={styles.setupButton} shape='round'>
              Setup New Game
            </Button>
          </Popconfirm>
        </div>
      )}
    </div>
  );
});
