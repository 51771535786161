export const DemoSystemNotice = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '10px 50px 0px 50px' }}>
      <h2 style={{ fontFamily: 'monospace', color: 'white' }}>DEMO SYSTEM</h2>
      <h2 style={{ fontFamily: 'monospace', color: 'white' }}>DEMO SYSTEM</h2>
      <h2 style={{ fontFamily: 'monospace', color: 'white' }}>DEMO SYSTEM</h2>
      <h2 style={{ fontFamily: 'monospace', color: 'white' }}>DEMO SYSTEM</h2>
    </div>
  );
};
