import { IAsset } from '@powertrader/schema';
import { getAssetEnergyMarketRoundLoad } from '../getFunctions';

export const cfdPerAsset = ({
  allRoundTradeWeightedAvg,
  strikePrice,
  generation
}: {
  allRoundTradeWeightedAvg: number;
  strikePrice: number;
  generation: number;
}) => {
  return (strikePrice - allRoundTradeWeightedAvg) * generation;
};

export const cfd = ({ allRoundTradeWeightedAvg, teamAssets, roundID }: { allRoundTradeWeightedAvg: number; teamAssets: IAsset[]; roundID: number }) =>
  teamAssets.reduce(
    (acc, asset) => {
      if (!asset.strikePrice) return acc;

      const result = cfdPerAsset({
        allRoundTradeWeightedAvg,
        strikePrice: asset.strikePrice,
        generation: getAssetEnergyMarketRoundLoad(roundID, asset)
      });

      if (result > 0) return { ...acc, income: result + acc.income };

      return { ...acc, expenditure: Math.abs(result) + acc.expenditure };
    },
    { income: 0, expenditure: 0 }
  );
