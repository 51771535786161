import { useState, useEffect, useContext, Fragment } from 'react';
import moment from 'moment';
import { IErrorProps } from '@powertrader/schema';
import { socket } from '../../socket/socket';
import { setErrorContext } from '../../context';

import styles from './ErrorLog.module.css';

export const ErrorLog = () => {
  const [errorLog, setErrorLog] = useState<IErrorProps[]>([]);

  const setError = useContext(setErrorContext);

  useEffect(() => {
    socket.emit('requestErrorLog', (error: IErrorProps['error'], data: IErrorProps[]) => {
      if (error) {
        setError({
          message: 'cannot get ErrorLog - IRONIC I KNOW',
          error
        });
      }
      if (data !== undefined) {
        setErrorLog(data);
      }
    });
  }, [setError]);

  useEffect(() => {
    socket.on('updateErrorLog', (data: IErrorProps) => {
      if (data !== undefined) {
        const newErrorLog = errorLog;
        newErrorLog.push(data);
        setErrorLog(newErrorLog);
      }
    });

    return () => {
      socket.off('updateErrorLog');
    };
  }, [errorLog]);

  const sortedErrorLog = errorLog.sort((a, b) => (b.timeStamp || 0) - (a.timeStamp || 0));
  return (
    <div className={styles.innerCard}>
      {sortedErrorLog.map((log, i) => {
        return (
          <Fragment key={i}>
            <p>
              <strong>{moment(+(log.timeStamp || 0)).format('YYYY-MM-DD HH:mm:ss')}</strong>
            </p>
            <p>UserID: {log.userID}</p>
            <p>Error Message: {log.message}</p>
            <p>Error Content: {log.error?.toString()}</p>
            <br />
          </Fragment>
        );
      })}
    </div>
  );
};
