import { controlDataContext, DisplayController, gameProgressContext, setErrorContext, setupDataContext, socket, Timer } from '@powertrader/core';
import { IControlDisplay } from '@powertrader/schema';
import { IGameAction } from '@powertrader/schema';
import { Button, message, Popconfirm } from 'antd';
import { throttle } from 'lodash';
import { memo, useContext } from 'react';
import styles from './GameFlow.module.css';
import { GameFlowAction } from './GameFlowAction/GameFlowAction';
import { GameFlowAddAction } from './GameFlowAddAction/GameFlowAddAction';
import { GameFlowPreset } from './GameFlowPreset/GameFlowPreset';
import { GameFlowRoundHeading } from './GameFlowRoundHeading/GameFlowRoundHeading';

interface IGameFlow {
  setControlDisplay: IControlDisplay['setControlDisplay'];
}
export const GameFlow = memo(({ setControlDisplay }: IGameFlow) => {
  const { settings } = useContext(setupDataContext);
  const { roundID } = useContext(gameProgressContext);
  const { gameActions } = useContext(controlDataContext);
  const setError = useContext(setErrorContext);

  const updateLiveActions = (action: IGameAction) => {
    socket.emit('updateGameAction', action, (E: Error | string, result?: string) => {
      if (result === 'complete') {
        return;
      }
      if (E) {
        message.error('Error updating action, try refresh not updated');
      }
    });
  };

  const handleAddRound = throttle(() => {
    const scenarioID = 1;
    socket.emit('addRound', scenarioID, (E: Error | string | null, result?: string) => {
      if (result === 'complete') {
        window.scrollTo(0, document.body.scrollHeight);
        message.success('Round added, please set scenario');
      }

      if (E) setError({ error: E, message: 'Error adding round' });
    });
  }, 500);

  const handleDeleteRound = () => {
    if (settings.currentRound >= settings.noOfRounds) {
      message.error('Cannot delete round in play');
      return;
    }
    socket.emit('deleteRound', (E: Error | string | null, result?: string) => {
      if (result === 'complete') message.success('Round deleted');

      if (E) setError({ error: E, message: 'Error deleting round' });
    });
  };
  const isSetup = settings.currentGamePhase === 'setup' || settings.currentGamePhase === 'portfolio';

  return (
    <>
      <div className={styles.timmerControls}>
        <Timer endTime={settings.timerEndTime} control />
        <DisplayController onClick={setControlDisplay} round={roundID} />
      </div>

      <div className={styles.mainContainer}>
        <h1 className={styles.pageHeading}>Game Configuration</h1>
        <div className={styles.innerContainer}>
          <div className={styles.gameRoundContainer}>
            {' '}
            {Array(settings.noOfRounds + 1)
              .fill('')
              .map((_, index) => (
                <div key={index}>
                  <GameFlowRoundHeading highlighted={settings.currentRound === index && !isSetup} label={index === 0 ? 'Demo' : 'Round ' + index} />
                  <div className={styles.gameActionsContainer}>
                    {gameActions.map(action =>
                      action.triggerRoundID === index ? (
                        <GameFlowAction key={action.id} action={action} updateLiveActions={updateLiveActions} />
                      ) : null
                    )}

                    {(settings.currentGamePhase === 'setup' || index > roundID) && (
                      <GameFlowAddAction
                        roundID={index}
                        isSharePriceFactorConfigured={
                          !!gameActions.find(action => action.actionUpdateType === 'sharePriceFactor' && action.triggerRoundID === index)
                        }
                      />
                    )}
                  </div>
                </div>
              ))}
            <div className={styles.roundControls}>
              <Button shape='round' ghost className={styles.button} onClick={() => handleAddRound()}>
                Add Round {settings.noOfRounds + 1}
              </Button>
              <Popconfirm
                title={`Are you sure? Round ${settings.noOfRounds} parameters will be lost`}
                placement='top'
                onConfirm={() => handleDeleteRound()}
                okText='Yes'
                cancelText='No'>
                {settings.noOfRounds > roundID && (
                  <Button shape='round' ghost className={styles.button}>
                    Delete Round {settings.noOfRounds}
                  </Button>
                )}
              </Popconfirm>
            </div>
          </div>
          <div className={styles.presetContainer}>
            <GameFlowPreset />
          </div>
        </div>
      </div>
    </>
  );
});
