import { mean } from 'lodash';
import {
  ISharePriceFactors,
  OpenTradeComplete,
  BaseTeam,
  IAssetsToScore,
  ICashAndStrategy,
  ISettings,
  ITeamRoundData,
  ICalcTeamScore,
  ITeamScore
} from '@powertrader/schema';

import { teamSharePrice } from './teamSharePrice';
import { socket } from '../socket/socket';

export interface ICalcSharePrice {
  settings: ISettings;
  teamID: number;
  teamType: BaseTeam['type'];
  roundID: number;
  assetsToScore: IAssetsToScore[];
  cashAndStrategy: ICashAndStrategy[];
  trades: OpenTradeComplete[];
  sharePriceFactors: ISharePriceFactors[];
  lastRoundScores: ITeamRoundData[];
  teamScores: ICalcTeamScore[];
  averageProfit?: number;
}

export const calcSharePrice = ({
  settings,
  teamID,
  teamType,
  roundID,
  assetsToScore,
  cashAndStrategy,
  trades,
  sharePriceFactors,
  lastRoundScores,
  teamScores,
  averageProfit
}: ICalcSharePrice): ITeamScore[] => {
  const newTeamScores: ITeamScore[] = [];
  if (teamType === 'ADMIN') {
    // calculate shareprice

    for (const teamDetails of assetsToScore) {
      if (teamDetails.teamType !== 'ADMIN') {
        const calcTeamScore = teamScores.find(ts => ts.teamID === teamDetails.teamID);
        const lastRoundScore = lastRoundScores.find(lrs => lrs.teamID === teamDetails.teamID);

        if (!calcTeamScore) {
          socket.emit('errorLog', {
            type: 'calculation',
            message: `calcSharePrice - No Team Scores ${teamDetails.teamID}`
          });
          throw new Error(`calcSharePrice - No Team Scores ${teamDetails.teamID}`);
        }
        newTeamScores.push({
          ...calcTeamScore,
          sharePrice: teamSharePrice({
            sharePriceFactors,
            cashAndStrategy,
            teamID: teamDetails.teamID,
            lastRoundSharePrice: lastRoundScore?.sharePrice ?? settings.startingSharePrice,
            averageProfit: mean(teamScores.map(ts => ts.totals.profit)),
            profitThisRound: calcTeamScore.totals.profit,
            physicalEmissionsThisRound: calcTeamScore.totals.emissions,
            carbonCertsThisRound: Math.min(0, calcTeamScore.totals.carbonCertificates),
            tradesThisRound: calcTeamScore.totals.noOfTrades,
            tradesLastRound: trades.filter(
              t =>
                (t.openTrade.offeringTeam.teamID === teamDetails.teamID || t.receivingTeam.teamID === teamDetails.teamID) &&
                t.openTrade.startRoundID <= roundID - 1 &&
                t.openTrade.endRoundID >= roundID - 1
            ).length
          })
        });
      }
    }
  } else {
    const lastRoundScore = lastRoundScores.find(lrs => lrs.teamID === teamID);
    newTeamScores.push({
      ...teamScores[0],
      sharePrice: teamSharePrice({
        sharePriceFactors,
        cashAndStrategy,
        teamID,
        lastRoundSharePrice: lastRoundScore?.sharePrice ?? settings.startingSharePrice,
        averageProfit,
        profitThisRound: teamScores[0].totals.profit,
        physicalEmissionsThisRound: teamScores[0].totals.emissions,
        carbonCertsThisRound: Math.min(0, teamScores[0].totals.carbonCertificates),
        tradesThisRound: teamScores[0].totals.noOfTrades,
        tradesLastRound: trades.filter(
          t =>
            (t.openTrade.offeringTeam.teamID === teamID || t.receivingTeam.teamID === teamID) &&
            t.openTrade.startRoundID <= roundID - 1 &&
            t.openTrade.endRoundID >= roundID - 1
        ).length
      })
    });
  }
  return newTeamScores;
};
