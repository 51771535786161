import { clamp, round, sum, sumBy } from 'lodash';
import { BilateralTrade, IAsset, IMarketTotal, ITeamRoundData, ITeamScore, ITeamScoretotals, Market, OpenTradeComplete } from '@powertrader/schema';

import { getAssetMarketRoundLoad, getMarket, getMaxScenarioMarketLoad, getMinDemand, getRawDemand } from './getFunctions';
import { socket } from '../socket/socket';
import { cfd } from './subCalcs/cfd';

// Adjust line length in Package.JSON to 200 before editing

export interface ITeamBalances {
  roundID: number;
  marketTotalGen: IMarketTotal[];
  marketTotalSupply: IMarketTotal[];
  teamAssets: IAsset[];
  scenarioID: number;
  markets: Market[];
  electricityShortfallPrice: number;
  electricityBuyBackPrice: number;
  hydrogenShortfallPrice: number;
  carbonTax: number;
  trades: OpenTradeComplete[];
  allRoundTradeWeightedAvg: number;
  teamID: number;
  targetEmissionIntensity: number;
  bilateralTrades: BilateralTrade[];
  hydrogenStoredAtRoundStart: ITeamRoundData['hydrogenStored'];
}

export const teamBalances = ({
  roundID,
  marketTotalGen,
  marketTotalSupply,
  teamAssets,
  scenarioID,
  markets,
  electricityShortfallPrice,
  electricityBuyBackPrice,
  hydrogenShortfallPrice,
  carbonTax,
  trades,
  allRoundTradeWeightedAvg,
  teamID,
  targetEmissionIntensity,
  bilateralTrades,
  hydrogenStoredAtRoundStart
}: ITeamBalances) => {
  const thisRoundTeamTrades = trades.filter(t => t.openTrade.startRoundID <= roundID && t.openTrade.endRoundID >= roundID);
  const thisRoundTeamBilateralTrades = bilateralTrades.filter(t => t.startRoundID <= roundID && t.endRoundID >= roundID);
  const energyHydrogenMarkets = markets.filter(market => market.type === 'powerExchange' || market.type === 'hydrogenMarket');
  const energyMarkets = markets.filter(market => market.type === 'powerExchange');

  /** Generation for market, can be negative for storage // .totalLoad */
  const totalGenM = (marketID: Market['marketID']) => {
    const result = marketTotalGen.find(mtg => mtg.market.marketID === marketID)?.totalLoad;
    if (result !== undefined) {
      return result;
    }
    socket.emit('errorLog', {
      type: 'calculation',
      message: `Cannot get team ${teamID}'s generation total load for market ${marketID}`
    });

    return 0;
  };
  /** Supply for market and can be negative for storage // .totalLoad */
  const totalCustM = (marketID: Market['marketID']) => {
    const result = marketTotalSupply.find(mts => mts.market.marketID === marketID)?.totalLoad;
    if (result !== undefined) {
      return result;
    }
    socket.emit('errorLog', {
      type: 'calculation',
      message: `Cannot get team ${teamID}'s customer total load for market ${marketID}`
    });
    return 0;
  };

  /** Net trading volume per market, positive is energy in */
  const tradingM = (marketID: Market['marketID']) =>
    sum(
      thisRoundTeamTrades
        .filter(t => t.openTrade.market.marketID === marketID)
        .map(trade =>
          (trade.openTrade.dealType === 'buy' && trade.openTrade.offeringTeam.teamID === teamID) ||
          (trade.openTrade.dealType === 'sell' && trade.receivingTeam.teamID === teamID)
            ? trade.volume
            : -trade.volume
        )
    ) +
    sum(
      thisRoundTeamBilateralTrades
        .filter(trade => trade.market.marketID === marketID)
        .map(trade =>
          (trade.dealType === 'buy' && trade.offeringTeam.teamID === teamID) || (trade.dealType === 'sell' && trade?.receivingTeam?.teamID === teamID)
            ? trade.volume
            : -trade.volume
        )
    );

  let customerTotalShortR = 0;
  let allAssetsCustomerLongR = 0;

  const assetDemandM = (marketID: Market['marketID'], asset: IAsset) => getMaxScenarioMarketLoad(asset, marketID, scenarioID); // Customer demand for market 1 before flexing = demand numbers in the database of that round

  /** Amount customer is short in the market/asset */
  const assetShort = (marketID: Market['marketID'], asset: IAsset) =>
    Math.max(
      0,
      assetDemandM(marketID, asset) -
        (asset.electricityInterruptible || 0) -
        round((asset.flexible || 0) * getRawDemand(asset, scenarioID, 'electricity')) -
        getAssetMarketRoundLoad(marketID, roundID, asset)
    );
  /**  amount customer is long in the market/asset */
  const assetLong = (marketID: Market['marketID'], asset: IAsset) =>
    Math.max(
      0,
      getAssetMarketRoundLoad(marketID, roundID, asset) -
        assetDemandM(marketID, asset) -
        round((asset.flexible || 0) * getRawDemand(asset, scenarioID, 'electricity'))
    );

  const customerAssets = teamAssets.filter(ta => ta.electricityLoadType === 'customer');

  for (const asset of customerAssets) {
    const customerSupplyR = sum(energyMarkets.map(m => getAssetMarketRoundLoad(m.marketID, roundID, asset)));

    const shortRtemp = Math.max(0, getRawDemand(asset, scenarioID, 'electricity') - (asset.electricityInterruptible || 0) - customerSupplyR);
    const sumAssetShort = sum(energyMarkets.map(m => assetShort(m.marketID, asset)));
    customerTotalShortR += Math.max(shortRtemp, sumAssetShort);

    const longRtemp = Math.max(0, customerSupplyR - sum(energyMarkets.map(m => assetDemandM(m.marketID, asset))));
    allAssetsCustomerLongR += Math.max(longRtemp, sum(energyMarkets.map(m => assetLong(m.marketID, asset))));
  }

  const wholesaleShortM = (marketID: Market['marketID']) => Math.max(0, totalCustM(marketID) - totalGenM(marketID) - tradingM(marketID)); // amount calculated that wholesale business is short in Market 1
  const wholesaleLongM = (marketID: Market['marketID']) => Math.max(0, totalGenM(marketID) + tradingM(marketID) - totalCustM(marketID));

  const wholesaleBalance = energyHydrogenMarkets.map(market => {
    const short = wholesaleShortM(market.marketID);
    const long = wholesaleLongM(market.marketID);

    return {
      market,
      short,
      long,
      balance: long - short
    };
  });

  const tradedVolumeMarket = energyHydrogenMarkets.map(market => ({
    market,
    volume: tradingM(market.marketID)
  }));
  const tradingIncome =
    sum(
      thisRoundTeamTrades.map(trade =>
        (trade.openTrade.dealType === 'buy' && trade.openTrade.offeringTeam.teamID === teamID) ||
        (trade.openTrade.dealType === 'sell' && trade.receivingTeam.teamID === teamID)
          ? 0
          : trade.volume * trade.openTrade.price
      )
    ) +
    sum(
      thisRoundTeamBilateralTrades.map(trade =>
        (trade.dealType === 'buy' && trade.offeringTeam.teamID === teamID) || (trade.dealType === 'sell' && trade?.receivingTeam?.teamID === teamID)
          ? 0
          : trade.volume * trade.price
      )
    );
  const tradingExpenditure =
    sum(
      thisRoundTeamTrades.map(trade =>
        (trade.openTrade.dealType === 'buy' && trade.openTrade.offeringTeam.teamID === teamID) ||
        (trade.openTrade.dealType === 'sell' && trade.receivingTeam.teamID === teamID)
          ? trade.volume * trade.openTrade.price
          : 0
      )
    ) +
    sum(
      thisRoundTeamBilateralTrades.map(trade =>
        (trade.dealType === 'buy' && trade.offeringTeam.teamID === teamID) || (trade.dealType === 'sell' && trade?.receivingTeam?.teamID === teamID)
          ? trade.volume * trade.price
          : 0
      )
    );
  const customerTotalShortRPenalty = customerTotalShortR > 0 ? customerTotalShortR * electricityShortfallPrice : 0;

  const wholesaleShortR = sumBy(
    wholesaleBalance.filter(wb => wb.market.type === 'powerExchange'),
    a => a.short
  );
  const wholesaleLongR = sumBy(
    wholesaleBalance.filter(wb => wb.market.type === 'powerExchange'),
    a => a.long
  );

  const teamShortR = customerTotalShortR + wholesaleShortR; // Display This Number

  const teamLongR = allAssetsCustomerLongR + wholesaleLongR;

  const carbonMarketID = getMarket(markets, 'carbonMarket').marketID;
  const hydrogenMarketID = getMarket(markets, 'hydrogenMarket').marketID;

  const customerMinDemand =
    sum(customerAssets.map(asset => getRawDemand(asset, scenarioID, 'electricity'))) - sumBy(customerAssets, a => a.electricityInterruptible || 0);

  const consumerAssets = teamAssets.filter(ta => ta.hydrogenLoadType === 'consumer');

  const carbon: ITeamScore['carbon'] = {
    generation: sumBy(marketTotalGen, a => a.carbonIntensity) + sumBy(marketTotalSupply, a => a.carbonIntensity),
    supply: sum(
      customerAssets.map(
        asset =>
          sum(energyMarkets.map(m => getAssetMarketRoundLoad(m.marketID, roundID, asset))) *
          (targetEmissionIntensity * (asset.carbonCertificates || 1))
      )
    ),
    trading: tradingM(carbonMarketID)
  };
  const carbonCertificates = round(carbon.supply - carbon.generation + carbon.trading);

  const hydrogenCustomerMinDemand =
    sum(consumerAssets.map(asset => getRawDemand(asset, scenarioID, 'hydrogen'))) - sumBy(consumerAssets, a => a.hydrogenInterruptible || 0);

  const hydrogenUnfulfilled = consumerAssets.reduce((total, asset) => {
    return total + Math.max(0, getMinDemand(asset, scenarioID, 'hydrogen') - getAssetMarketRoundLoad(hydrogenMarketID, roundID, asset));
  }, 0);

  const hydrogenProduced = teamAssets.reduce((acc, curr) => {
    if (curr.hydrogenLoadType !== 'producer') return acc;

    return acc + (curr.load.find(load => load.marketID === hydrogenMarketID && load.roundID === roundID)?.load || 0);
  }, 0);
  const hydrogenConsumed = teamAssets.reduce((acc, curr) => {
    if (curr.hydrogenLoadType !== 'consumer') return acc;

    return acc + (curr.load.find(load => load.marketID === hydrogenMarketID && load.roundID === roundID)?.load || 0);
  }, 0);

  const hydrogenTraded = tradingM(hydrogenMarketID);

  const hydrogenStorageCapacity = sumBy(teamAssets, a => a.hydrogenStorageCapacity || 0);
  const changeInHydrogenStorage = round(
    clamp(hydrogenProduced + hydrogenTraded - hydrogenConsumed, -hydrogenStoredAtRoundStart, hydrogenStorageCapacity - hydrogenStoredAtRoundStart),
    1
  );
  const currentStored = hydrogenStoredAtRoundStart + changeInHydrogenStorage;

  const hydrogenWholesaleBalance = hydrogenProduced - changeInHydrogenStorage + hydrogenTraded - hydrogenConsumed;
  const hydrogen: ITeamScore['hydrogen'] = {
    initialStored: hydrogenStoredAtRoundStart,
    changeInHydrogenStorage, // positive is put into storage
    currentStored,
    produced: hydrogenProduced,
    consumed: hydrogenConsumed,
    trading: hydrogenTraded,
    wholesaleBalance: hydrogenWholesaleBalance,
    minDemand: hydrogenCustomerMinDemand,
    unfulfilled: hydrogenUnfulfilled,
    capacity: hydrogenStorageCapacity
  };

  const electricityPenalty = electricityShortfallPrice * (customerTotalShortR + wholesaleShortR);
  const hydrogenWholesalePenalty = round(Math.max(0, -hydrogenWholesaleBalance)) * hydrogenShortfallPrice;
  const hydrogenCustomerPenalty = round(hydrogen.unfulfilled) * hydrogenShortfallPrice;
  const imbalance = {
    totalPenalty: electricityPenalty + hydrogenWholesalePenalty + hydrogenCustomerPenalty,
    electricityPenalty,
    hydrogenTotalPenalty: hydrogenWholesalePenalty + hydrogenCustomerPenalty,
    hydrogenCustomerPenalty,
    income: electricityBuyBackPrice * teamLongR
  };
  const subsidyIncome = sumBy(marketTotalGen, a => a.subsidyIncome);
  const isGeneratorFn = (a: IAsset) => a.electricityLoadType === 'generator' || (a.electricityLoadType === null && a.hydrogenLoadType === 'producer');
  const isCustomerFn = (a: IAsset) => a.electricityLoadType === 'customer' || (a.electricityLoadType === null && a.hydrogenLoadType === 'consumer');

  const generatorAndProducers = teamAssets.filter(isGeneratorFn);
  const customersAndConsumers = teamAssets.filter(isCustomerFn);

  const customerCFDs = cfd({ allRoundTradeWeightedAvg, teamAssets: customersAndConsumers, roundID });
  const generatorCFDs = cfd({ allRoundTradeWeightedAvg, teamAssets: generatorAndProducers, roundID });

  const genCostBreakdown = {
    runningCost: sumBy(marketTotalGen, a => a.genCost),
    fixedCost: sumBy(generatorAndProducers, a => a.fixedCost || 0),
    purchaseCost: sumBy(generatorAndProducers, a => a.purchaseCost || 0),
    cfdLosses: generatorCFDs.expenditure
  };

  const customerIncomeBreakdown = { supplyIncome: sumBy(marketTotalSupply, a => a.netSupplyIncome), cfdGains: customerCFDs.income };

  const customerCostBreakdown = {
    fixedCost: sumBy(teamAssets.filter(isCustomerFn), a => a.fixedCost || 0),
    purchaseCost: sumBy(teamAssets.filter(isCustomerFn), a => a.purchaseCost || 0),
    cfdLosses: customerCFDs.expenditure
  };
  const totals: ITeamScoretotals = {
    generation: sumBy(
      marketTotalGen.filter(mtg => mtg.market.type === 'powerExchange'),
      a => a.totalLoad
    ),
    supply: sumBy(
      marketTotalSupply.filter(mtg => mtg.market.type === 'powerExchange'),
      a => a.totalLoad
    ),
    genCostBreakdown,
    generationCost: round(sum(Object.values(genCostBreakdown))),
    genIncomeBreakdown: { subsidyIncome, cfdGains: generatorCFDs.income },
    generationIncome: subsidyIncome + generatorCFDs.income,
    customerIncomeBreakdown,
    customerIncome: round(sum(Object.values(customerIncomeBreakdown))),
    customerCostBreakdown,
    customerCost: round(sum(Object.values(customerCostBreakdown))),
    customerMinDemand, // calculate minimum demand from customers
    hydrogenCustomerMinDemand,
    tradingIncome,
    tradingExpenditure,
    tradingAccount: tradingIncome - tradingExpenditure,
    noOfTrades: thisRoundTeamTrades.length + thisRoundTeamBilateralTrades.length * 2,
    wholesaleBalance: wholesaleLongR - wholesaleShortR,
    wholesaleBalanceAccountShort: wholesaleShortR * electricityShortfallPrice,
    wholesaleBalanceAccountLong: wholesaleLongR * electricityBuyBackPrice,
    wholesaleBalanceAccount: wholesaleLongR * electricityBuyBackPrice - wholesaleShortR * electricityShortfallPrice - hydrogenWholesalePenalty,
    carbonCertificates,
    hydrogen: hydrogen.currentStored,
    emissions: carbon.generation,
    pollutionPenalty: Math.max(0, -carbonCertificates) * carbonTax,
    income: 0,
    expenditure: 0,
    profit: 0
  };

  return {
    wholesaleBalance,
    customerTotalShortR,
    customerTotalShortRPenalty,
    tradedVolumeMarket,
    totals,
    teamLongR,
    teamShortR,
    imbalance,
    carbon,
    hydrogen
  };
};
