import {
  GameSettingsControl,
  ReviewPage,
  allTeamAssetsContext,
  bilateralTradesContext,
  calcSharePrice,
  calcTeamScores,
  controlDataContext,
  gameProgressContext,
  getMaxScenarioLoad,
  getMinDemand,
  openTradesCompletedContext,
  setErrorContext,
  setupDataContext,
  socket,
  teamRoundDataContext
} from '@powertrader/core';
import type { BilateralTrade, IAsset, ICashAndStrategy, IControlDisplay, IErrorProps, ITeamRoundData, ITeamScore } from '@powertrader/schema';
import { debounce, isEqual, sum } from 'lodash';
import { memo, useContext, useEffect, useRef, useState } from 'react';

import styles from './ControlArea.module.css';
import { ControlView } from './ControlView/ControlView';
import { EventLogView } from './EventLogView/EventLogView';
import { GameFlow } from './GameFlow/GameFlow';

const ControlArea = memo(() => {
  const { teams, settings, user, markets } = useContext(setupDataContext);
  const { scenarioID, roundID } = useContext(gameProgressContext);
  const { sharePriceFactors } = useContext(controlDataContext);

  const teamRoundData = useContext(teamRoundDataContext);
  const allTeamAssets = useContext(allTeamAssetsContext);

  const setError = useContext(setErrorContext);
  const bilateralTrades = useContext(bilateralTradesContext);
  const [allAssets, setAllAssets] = useState<IAsset[]>([]);
  const [selectedTeamID, setSelectedTeamID] = useState(1);
  const [teamScores, setTeamScores] = useState<ITeamScore[]>();
  const [cashAndStrategy, setCashAndStrategy] = useState<ICashAndStrategy[]>();
  const [lastRoundScores, setLastRoundScores] = useState<ITeamRoundData[]>();
  const completedTrades = useContext(openTradesCompletedContext);
  const [acceptedBilateralTrades, setAcceptedBilateralTrades] = useState<BilateralTrade[]>([]);
  const [controlDisplay, setControlDisplay] = useState<IControlDisplay['props']>('controls');
  const [systemStats, setSystemStats] = useState({ generation: 0, minDemand: 0 });

  useEffect(() => {
    socket.emit('requestTeamAssets', (error: IErrorProps['error'], data: IAsset[]) => {
      if (error) {
        setError({ message: 'cannot get teamAssetsData', error });
      }
      if (data !== undefined) {
        setAllAssets(data);
      }
    });

    socket.on('updateTeamAssets', (data: IAsset[]) => {
      // console.log(data)
      if (data !== undefined) {
        setAllAssets(data);
      }
    });

    return () => {
      socket.off('updateTeamAssets');
    };
  }, [setError]);

  useEffect(() => {
    setCashAndStrategy(
      teamRoundData
        .filter(tr => tr.roundID === roundID)
        .map(tr => ({
          teamID: tr.teamID,
          cashAdjustment: tr.cashAdjustment,
          shareholderSentiment: tr.shareholderSentiment
        }))
    );

    setLastRoundScores(teamRoundData.filter(tr => tr.roundID === roundID - 1));
  }, [roundID, teamRoundData]);

  useEffect(() => {
    const filteredBilateralTrades = bilateralTrades.filter(bT => bT.status === 'accepted');
    if (!isEqual(filteredBilateralTrades, acceptedBilateralTrades)) {
      setAcceptedBilateralTrades(filteredBilateralTrades);
    }
  }, [acceptedBilateralTrades, bilateralTrades]);

  useEffect(() => {
    const systemGeneration = sum(
      allAssets.filter(asset => asset.teamID && asset.electricityLoadType === 'generator').map(asset => getMaxScenarioLoad(asset, scenarioID))
    );
    const systemDemand = sum(
      allAssets.filter(asset => asset.teamID && asset.electricityLoadType === 'customer').map(asset => getMinDemand(asset, scenarioID, 'electricity'))
    );
    setSystemStats({ generation: systemGeneration, minDemand: systemDemand });
  }, [allAssets, scenarioID]);

  const debounceMainCalc = useRef(
    debounce(
      ({
        settings,
        scenarioID,
        roundID,
        allTeamAssets,
        cashAndStrategy,
        completedTrades,
        user,
        sharePriceFactors,
        lastRoundScores,
        bilateralTrades
      }) => {
        if (cashAndStrategy && allTeamAssets && sharePriceFactors && bilateralTrades && !settings.newGame) {
          const teamScoresResults = calcTeamScores({
            settings,
            markets,
            scenarioID,
            roundID,
            assetsToScore: allTeamAssets,
            cashAndStrategy,
            trades: completedTrades,
            bilateralTrades,
            lastRoundScores
          });

          const results = calcSharePrice({
            settings,
            teamID: user.teamID,
            teamType: user.teamType,
            roundID,
            assetsToScore: allTeamAssets,
            cashAndStrategy,
            trades: completedTrades,
            sharePriceFactors,
            lastRoundScores,
            teamScores: teamScoresResults
          });

          setTeamScores(results);
        }
      },
      250
    )
  );

  useEffect(() => {
    debounceMainCalc.current({
      settings,
      scenarioID,
      roundID,
      allTeamAssets,
      cashAndStrategy,
      completedTrades,
      user,
      sharePriceFactors,
      lastRoundScores,
      bilateralTrades: acceptedBilateralTrades
    });
  }, [
    completedTrades,
    allTeamAssets,
    cashAndStrategy,
    roundID,
    scenarioID,
    settings,
    sharePriceFactors,
    lastRoundScores,
    user.teamID,
    user.teamType,
    user,
    acceptedBilateralTrades
  ]);

  const selectedTeamScore = teamScores?.find(ts => ts.teamID === selectedTeamID);
  const selectedTeam = teams.find(team => selectedTeamID === team.teamID);

  if (settings.newGame) {
    return (
      <div data-testid='control-area-new-game' className={styles.controlAreaContainer}>
        <h1>Initial Setup</h1>

        <div className={styles.setupArea}>
          <GameSettingsControl />
        </div>
      </div>
    );
  }
  const selectedTeamRoundData = teamRoundData.find(tr => tr.teamID === selectedTeamID && tr.roundID === roundID);
  if (!(teamScores && selectedTeamScore && selectedTeam)) {
    return null;
  }
  return (
    <div data-testid='control-area' className={styles.controlAreaContainer}>
      {controlDisplay === 'review' && <ReviewPage controlDisplay={controlDisplay} setControlDisplay={setControlDisplay} teamScores={teamScores} />}

      {controlDisplay === 'controls' && (
        <ControlView
          selectedTeamScore={selectedTeamScore}
          allAssets={allAssets}
          selectedTeam={selectedTeam}
          selectedTeamRoundData={selectedTeamRoundData}
          selectedTeamID={selectedTeamID}
          setSelectedTeamID={setSelectedTeamID}
          systemStats={systemStats}
          teamScores={teamScores}
          setControlDisplay={setControlDisplay}
        />
      )}
      {controlDisplay === 'gameFlow' && <GameFlow setControlDisplay={setControlDisplay} />}

      {controlDisplay === 'eventLog' && <EventLogView setControlDisplay={setControlDisplay} roundID={roundID} />}
    </div>
  );
});

export default ControlArea;
