import { Popconfirm, Button } from 'antd';
import { socket, DisplayController, EventLog, ErrorLog } from '@powertrader/core';
import { memo } from 'react';
import { IControlDisplay, IGameProgress } from '@powertrader/schema';
import styles from './EventLogView.module.css';
interface IEventLogView {
  setControlDisplay: IControlDisplay['setControlDisplay'];
  roundID: IGameProgress['roundID'];
}
export const EventLogView = memo(({ setControlDisplay, roundID }: IEventLogView) => {
  return (
    <>
      <div className={styles.logsHeader}>
        {' '}
        <h2>Event & Error Logs</h2>
        <Popconfirm title='Are you sure?' onConfirm={() => socket.emit('refreshAll')} okText='Yes' cancelText='No'>
          {' '}
          <Button shape='round' ghost type='default'>
            Refresh All Devices
          </Button>
        </Popconfirm>
        <DisplayController style={{ position: 'absolute', top: '8px', right: '12px' }} onClick={setControlDisplay} round={roundID} />
      </div>
      <div className={styles.logs}>
        <div className={styles.logColumn}>
          <h3>Event Log</h3>
          <EventLog />
        </div>
        <div className={styles.logColumn}>
          <h3>Error Log</h3>
          <ErrorLog />
        </div>
      </div>
    </>
  );
});
