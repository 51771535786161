import React, { useContext } from 'react';
import { Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { bilateralTradesContext, gameProgressContext } from '../../../context';

interface DataType {
  key: React.Key;
  offeringTeam: string;
  dealType: string;
  receivingTeam: string;
  price: number;
  volume: number;
  market: string;
  status: string;
  tradedAt: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Offering',
    dataIndex: 'offeringTeam',
    sorter: (a, b) => a.offeringTeam.localeCompare(b.offeringTeam)
  },
  {
    title: 'Deal Type',
    dataIndex: 'dealType',
    sorter: (a, b) => a.dealType.localeCompare(b.dealType)
  },
  {
    title: 'Receiving',
    dataIndex: 'receivingTeam',
    sorter: (a, b) => a.receivingTeam.localeCompare(b.receivingTeam)
  },
  {
    title: 'Price',
    dataIndex: 'price',
    sorter: (a, b) => a.price - b.price
  },
  {
    title: 'Volume',
    dataIndex: 'volume',
    sorter: (a, b) => a.volume - b.volume
  },
  {
    title: 'Market',
    dataIndex: 'market',
    sorter: (a, b) => a.market.localeCompare(b.market)
  },
  {
    title: 'Status',
    dataIndex: 'status',
    sorter: (a, b) => a.status.localeCompare(b.status),
    render: (_, { status }) => (
      <Tag color={status === 'offered' ? 'geekblue' : 'green'} key={status}>
        {status.toUpperCase()}
      </Tag>
    )
  },
  {
    title: 'TradedAt',
    dataIndex: 'tradedAt',
    sorter: (a, b) => a.tradedAt.localeCompare(b.tradedAt),
    defaultSortOrder: 'descend'
  }
];

export const TradeDetailsTable = () => {
  const bilateralTrades = useContext(bilateralTradesContext);
  const { roundID } = useContext(gameProgressContext);

  const data = bilateralTrades
    .filter(trade => trade.startRoundID === roundID && (trade.status === 'offered' || trade.status === 'accepted'))
    .map(trade => ({
      key: trade.id,
      offeringTeam: trade.offeringTeam.label,
      dealType: trade.dealType,
      receivingTeam: trade.receivingTeam?.label || '--',
      price: trade.price,
      volume: trade.volume,
      market: trade.market.label,
      status: trade.status,
      tradedAt: trade.tradedAt?.toString() || '--'
    }));
  return <Table bordered pagination={{ pageSize: 15, showSizeChanger: true }} columns={columns} dataSource={data} />;
};
