import { GameAction, GamePreset, IControlData, IUser } from '@powertrader/schema';
import { message } from 'antd';
import { cloneDeep } from 'lodash';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { socket } from './socket';

interface IUseGameFlowSocket {
  isLoggedIn: boolean;
  user: IUser | undefined;
  setControlData: Dispatch<SetStateAction<IControlData | undefined>>;
}
export const useGameFlowSocket = ({ isLoggedIn, user, setControlData }: IUseGameFlowSocket) =>
  useEffect(() => {
    if (isLoggedIn && user) {
      socket.on('addGameAction', (data: GameAction | GameAction[]) => {
        const actions = Array.isArray(data) ? data : [data];

        setControlData(prevState => {
          if (prevState?.gameActions && prevState.gamePresets && prevState.sharePriceFactors) {
            const newState = cloneDeep(prevState);
            for (const action of actions) {
              newState.gameActions.push(action);
            }

            return newState;
          }
          socket.emit('errorLog', { error: 'Error when adding game actions on client', userID: user?.userID });
        });
      });

      socket.on('updateGameAction', (action: GameAction) => {
        setControlData(prevState => {
          if (prevState?.gameActions && prevState.gamePresets && prevState.sharePriceFactors) {
            const newState = cloneDeep(prevState);

            const index = newState.gameActions?.findIndex(gameAction => gameAction.id === action.id);

            if (index !== -1) newState.gameActions[index] = action;

            return newState;
          }
          socket.emit('errorLog', { error: 'Error when updaing new game action on client', userID: user?.userID });
        });
      });
      socket.on('removeGameAction', (data: GameAction[]) => {
        const actions = Array.isArray(data) ? data : [data];
        setControlData(prevState => {
          if (prevState?.gameActions && prevState.gamePresets && prevState.sharePriceFactors) {
            const newState = cloneDeep(prevState);
            for (const action of actions) {
              const index = newState.gameActions?.findIndex(gameAction => gameAction.id === action.id);

              if (index !== -1) newState.gameActions.splice(index, 1);
            }
            return newState;
          }
          socket.emit('errorLog', { error: 'Error when updaing new game action on client', userID: user?.userID });
        });
      });
      socket.on('addNewGamePreset', (preset: GamePreset) => {
        setControlData(prevState => {
          if (prevState?.gamePresets && prevState.sharePriceFactors) {
            const newState = cloneDeep(prevState);

            newState.gamePresets?.push(preset);
            return newState;
          }
          socket.emit('errorLog', { error: 'Error when receiving new game preset on client', userID: user?.userID });
        });
      });
      socket.on('removeGamePreset', (preset: GamePreset) => {
        setControlData(prevState => {
          if (prevState?.gamePresets && prevState.sharePriceFactors) {
            const newState = cloneDeep(prevState);

            const index = newState.gamePresets?.findIndex(gamePreset => gamePreset.id === preset.id);

            if (index !== -1) newState.gamePresets.splice(index, 1);

            return newState;
          }
          socket.emit('errorLog', { error: 'Error when removing new game preset on client', userID: user?.userID });
        });
      });
      socket.on('updateGamePreset', (preset: GamePreset) => {
        setControlData(prevState => {
          if (prevState?.gamePresets && prevState.sharePriceFactors) {
            const newState = cloneDeep(prevState);

            const index = newState.gamePresets?.findIndex(gamePreset => gamePreset.id === preset.id);

            if (index !== -1) newState.gamePresets[index] = preset;

            return newState;
          }
          socket.emit('errorLog', { error: 'Error when updating game preset on client', userID: user?.userID });
        });
      });
    }

    return () => {
      socket.off('addGameAction');
      socket.off('updateGameAction');
      socket.off('removeGameAction');
      socket.off('addNewGamePreset');
      socket.off('removeGamePreset');
      socket.off('updateGamePreset');
    };
  }, [isLoggedIn, setControlData, user]);
