import { setupDataContext, gameProgressContext, Trading } from '@powertrader/core';

import { memo, useContext, useState } from 'react';
import { IAdditionalInformationDisplayProps } from '@powertrader/schema';
import { TradingModal } from '../../TradingModal';
import styles from './CentreStage.module.css';
import { CSAuctionDisplay } from './CSAuctionDisplay';
import { CSSingleHeading } from './CSSingleHeading';
import { CSWelcome } from './CSWelcome';
import { UpgradeList } from './UpgradeList/UpgradeList';
import { StrategyStatement } from './StrategyStatement/StrategyStatement';
import { TeamStats } from './TeamStats/TeamStats';
import { Button } from 'antd';

interface ICentreStage {
  additionalInformationDisplay: IAdditionalInformationDisplayProps['value'];
  setAdditionalInformationDisplay: IAdditionalInformationDisplayProps['setState'];
}

export const CenterStage = memo(({ additionalInformationDisplay, setAdditionalInformationDisplay }: ICentreStage) => {
  const { user, settings } = useContext(setupDataContext);
  const gameProgress = useContext(gameProgressContext);
  const [showTradingHelpModal, setShowTradingHelpModal] = useState(false);

  if (additionalInformationDisplay === 'upgradeList') return <UpgradeList setAdditionalInformationDisplay={setAdditionalInformationDisplay} />;
  if (additionalInformationDisplay === 'teamStats') return <TeamStats setAdditionalInformationDisplay={setAdditionalInformationDisplay} />;
  if (additionalInformationDisplay === 'bilaterials')
    return (
      <div className={styles.tradingWrapper}>
        <Button shape='round' ghost type='default' className={styles.switchView} onClick={() => setAdditionalInformationDisplay(undefined)}>
          Switch to Strategy
        </Button>
        <TradingModal showTradingHelpModal={showTradingHelpModal} setShowTradingHelpModal={setShowTradingHelpModal} />

        <Trading setShowTradingHelpModal={setShowTradingHelpModal} defaultReqType='BilateralTrade' />
      </div>
    );
  if (settings.returnToPlenary) return <CSSingleHeading text='Return to Main Plenary' />;

  if (settings.isAuctionActive) return <CSAuctionDisplay />;

  if (settings.allowStrategyInput)
    return (
      <>
        {!!settings.allowHedging && (
          <Button shape='round' ghost type='default' className={styles.switchView} onClick={() => setAdditionalInformationDisplay('bilaterials')}>
            Switch to Bilaterals
          </Button>
        )}
        <StrategyStatement user={user} roundID={gameProgress.roundID} />
      </>
    );

  if (settings.allowTrading)
    return (
      <div className={styles.tradingWrapper}>
        <TradingModal showTradingHelpModal={showTradingHelpModal} setShowTradingHelpModal={setShowTradingHelpModal} />

        <Trading setShowTradingHelpModal={setShowTradingHelpModal} />
      </div>
    );
  if (settings.currentGamePhase === 'demo') return <CSWelcome />;
  return <CSSingleHeading text='Welcome to Power Trader' logo />;
});
