import { memo } from 'react';
import styles from './GameFlowRoundHeading.module.css';
interface IGameFlowRoundHeading {
  label: string;
  highlighted: boolean;
}
export const GameFlowRoundHeading = memo(({ label, highlighted }: IGameFlowRoundHeading) => {
  return (
    <div className={styles.container}>
      <div className={styles.roundHeading} style={{ borderColor: highlighted ? 'green' : 'white', borderWidth: highlighted ? '3px' : '2px' }}>
        <h3>{label}</h3>
      </div>
      <hr className={styles.line} color='black' />
    </div>
  );
});
