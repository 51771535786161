import { Select } from 'antd';
import { controlDataContext, setErrorContext, setupDataContext, socket } from '@powertrader/core';
import { memo, useContext, useState } from 'react';
import styles from './GameFlowAddAction.module.css';
import { IAssetTypeAdjustment, INewGameAction, ISettingsDetails } from '@powertrader/schema';
const { Option } = Select;

interface IGameFlowAddAction {
  roundID: number;
  isSharePriceFactorConfigured: boolean;
  disabled?: boolean;
}

export const GameFlowAddAction = memo(({ roundID, isSharePriceFactorConfigured, disabled }: IGameFlowAddAction) => {
  const setError = useContext(setErrorContext);
  const [selectValue, setSelectValue] = useState<number | string | undefined>(undefined);
  const { settingDetails } = useContext(setupDataContext);
  const { sharePriceFactors, gameActions } = useContext(controlDataContext);

  const handleAddSetting = (id: ISettingsDetails['baseSettingsID']) => {
    const newSetting = settingDetails.find(setting => setting.baseSettingsID === id);
    if (!newSetting) {
      setError({ error: 'Failed to adding setting to round', message: 'Failed to adding setting to round' });
      return;
    }
    const action: INewGameAction = {
      triggerRoundID: roundID,
      actionUpdateType: 'setting',
      entryID: newSetting.baseSettingsID,
      settingValue: newSetting?.value,
      actionParameters: null
    };

    socket.emit('addGameAction', action, (E: Error | string, result?: string) => {
      if (result === 'complete') {
        setSelectValue(undefined);
        return;
      }

      if (E) {
        setError({ error: E, message: 'Failed to add setting to round' });
        return;
      }
    });
  };

  const handleAddSharePriceFactor = () => {
    const newSharePriceFactor = sharePriceFactors.map(spf => ({
      baseLimitsID: spf.baseLimitsID,
      bestExpectation: spf.bestExpectation,
      worstExpectation: spf.worstExpectation,
      growthLimit: spf.growthLimit
    }));

    const action: INewGameAction = {
      triggerRoundID: roundID,
      actionUpdateType: 'sharePriceFactor',
      entryID: null,
      settingValue: null,
      actionParameters: newSharePriceFactor
    };

    socket.emit('addGameAction', action, (E: Error | string, result?: string) => {
      if (result === 'complete') {
        setSelectValue(undefined);
        return;
      }

      if (E) {
        setError({ error: E, message: 'Failed to add sharePriceFactor to round' });
        return;
      }
    });
  };

  const handleAddAssetTypeAdjustment = () => {
    const newAssetTypeAdjustment: IAssetTypeAdjustment = {
      actions: [{ value: '', property: '', adjustmentType: 'exact' }],
      criteria: [{ value: '', property: '' }]
    };

    const action: INewGameAction = {
      triggerRoundID: roundID,
      actionUpdateType: 'assetTypeAdjustment',
      entryID: null,
      settingValue: null,
      actionParameters: newAssetTypeAdjustment
    };

    socket.emit('addGameAction', action, (E: Error | string, result?: string) => {
      if (result === 'complete') {
        setSelectValue(undefined);
        return;
      }

      if (E) {
        setError({ error: E, message: 'Failed to add assetTypeAdjustment to round' });
        return;
      }
    });
  };

  const settingsList = settingDetails.filter(settings => {
    if (settings.changeable === 'no' || settings.changeable === 'setup') return false;

    const isAlreadyUsed = !!gameActions.find(
      gA => gA.triggerRoundID === roundID && settings.baseSettingsID === gA.entryID && gA.actionUpdateType === 'setting'
    );

    return !isAlreadyUsed;
  });
  return (
    <div className={styles.container}>
      <Select
        disabled={disabled}
        showSearch
        placeholder='Select a Setting'
        optionFilterProp='children'
        className={styles.selectSetting}
        value={selectValue}
        onChange={value => {
          setSelectValue(value);
          if (value === 'sharePriceFactors') {
            handleAddSharePriceFactor();
          } else if (value === 'assetTypeAdjustment') {
            handleAddAssetTypeAdjustment();
          } else {
            handleAddSetting(Number(value));
          }
        }}>
        {settingsList.map(setting => (
          <Option key={setting.baseSettingsID} value={setting.baseSettingsID}>
            {setting.label}
          </Option>
        ))}
        {!isSharePriceFactorConfigured && (
          <Option key={'sharePriceFactors'} value={'sharePriceFactors'}>
            Share Price Factors
          </Option>
        )}
        {
          <Option key={'assetTypeAdjustment'} value={'assetTypeAdjustment'}>
            Asset Type Adjustment
          </Option>
        }
      </Select>
    </div>
  );
});
