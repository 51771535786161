import { useState, memo } from 'react';
import { IUser, BaseTeam } from '@powertrader/schema';
import { DownOutlined } from '@ant-design/icons';
import { message } from 'antd';

import styles from './TeamSwitch.module.css';

interface ITeamSwitch {
  user: IUser;
  teams: BaseTeam[];
}
export const TeamSwitch = memo(({ user, teams }: ITeamSwitch) => {
  const [showTeamSwitch, setTeamSwitch] = useState(false);

  return (
    <div style={showTeamSwitch ? { top: '-2px' } : {}} className={styles.switchContainer}>
      <div className={styles.switcher}>
        <h5>Switch to a different team</h5>
        <div className={styles.buttonRow}>
          {teams.map(team => {
            return (
              <button
                type='button'
                key={team.teamID}
                data-testid={`team-switch-${team.teamID}`}
                onClick={() => {
                  if (team.teamID !== user.teamID) {
                    sessionStorage.clear();
                    sessionStorage.setItem(
                      'user',
                      JSON.stringify({
                        userID: user.userID,
                        userName: user.userName,
                        role: user.role,
                        teamType: team.type,
                        teamID: team.teamID
                      })
                    );

                    window.location.reload();
                  } else {
                    message.info('You are already switched to this team');
                  }
                }}
                style={{
                  borderColor: team.color,
                  backgroundColor: team.color
                }}>
                {team.label}
              </button>
            );
          })}
        </div>
      </div>
      <button data-testid='team-switch' onClick={() => setTeamSwitch(prev => !prev)} className={styles.showHideSwitch}>
        <DownOutlined style={showTeamSwitch ? { transform: 'rotate(180deg)' } : {}} className={styles.icon} />
      </button>
    </div>
  );
});
