import { message, Rate } from 'antd';
import { memo, useContext, useState } from 'react';
import { OpenTrade, IErrorProps, BaseTeam } from '@powertrader/schema';
import { socket } from '../../../socket/socket';
import { setErrorContext } from '../../../context';

import styles from './OTDealOption.module.css';

interface IOTDealOption {
  trade: OpenTrade;
  teamID: BaseTeam['teamID'];
  allowDeal: boolean;
}
const OTDealOption = memo<IOTDealOption>(({ trade, teamID, allowDeal }) => {
  const setError = useContext(setErrorContext);
  const [currentValue, setCurrentValue] = useState(0);
  const { id, volumeRemaining } = trade;
  const requestTradeDeal = (purchaseVolume: number) => {
    socket.emit(
      'acceptTrade',
      {
        id,
        volumeToPurchase: purchaseVolume,
        acceptingTeamID: teamID
      },
      (error: IErrorProps['error'] | null, userWarning: string | null, data: 'complete') => {
        if (error) {
          setError({
            message: `Failed buying or selling trade (${purchaseVolume} at ${trade.price} from team ${trade.offeringTeam.label} to team ${teamID})`,
            error
          });
        } else if (userWarning) {
          message.error(userWarning);
        } else if (data === 'complete') {
          message.success(`Trade Complete - ${purchaseVolume} ${purchaseVolume > 1 ? 'units' : 'unit'} at ${trade.price}`);
          socket.emit('eventLog', {
            type: 'trading',
            log: `${teamID} accepted a trade deal from ${trade.offeringTeam.label} to ${trade.dealType === 'sell' ? 'buy' : 'sell'} ${
              trade.volume
            } at ${trade.price} on the ${trade.market.label} market`
          });
        }
      }
    );
    setCurrentValue(0);
  };
  if (allowDeal) {
    return (
      <div className={styles.dealContainer}>
        <Rate
          className={styles.buyVolume}
          defaultValue={0}
          value={currentValue}
          count={volumeRemaining}
          character={({ index }) => Number(index) + 1}
          onChange={value => requestTradeDeal(value)}
        />
      </div>
    );
  }
  return (
    <p>
      {trade.volumeRemaining} {trade.market.volumeUnits}
    </p>
  );
});

export default OTDealOption;
