import { LoginOutlined } from '@ant-design/icons';
import { bilateralTradesContext, gameProgressContext, setErrorContext, setupDataContext, socket } from '@powertrader/core';
import { BilateralTrade, IErrorProps, INewTradeReq } from '@powertrader/schema';
import { Input, Typography, message, notification } from 'antd';
import classNames from 'classnames';
import { useContext, useState } from 'react';
import styles from './BilateralScreen.module.css';
import { decodeToken } from './decodeToken';
import { isInteger } from 'lodash';
const { Text } = Typography;

export const NewToken = ({
  code,
  setCode,
  isActive,
  setScannedBilateral
}: {
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  isActive: boolean;
  setScannedBilateral: React.Dispatch<React.SetStateAction<BilateralTrade | undefined>>;
}) => {
  const { user, markets, settings, teams } = useContext(setupDataContext);
  const setError = useContext(setErrorContext);
  const { roundID } = useContext(gameProgressContext);
  const bilateralTrades = useContext(bilateralTradesContext);

  const bilateral = bilateralTrades.find(bil => bil.token === code && bil.status === 'offered' && bil.startRoundID === roundID);

  const [newTradeReq, setNewTradeReq] = useState<INewTradeReq>({
    tradeType: 'powerExchange',
    marketID: 1,
    volume: 1,
    offeringTeamID: user.teamID,
    receivingTeamID: 0,
    type: 'BilateralTrade',
    dealType: 'sell',
    price: bilateral?.price ? bilateral?.price.toString() : '',
    startRoundID: roundID,
    endRoundID: roundID,
    token: code,
    ...decodeToken(code, setCode)
  });

  const submitTrade = () => {
    if (
      (+newTradeReq.price > 0 || +newTradeReq.price < 0) &&
      +newTradeReq.price > settings.minTradeAllowed &&
      +newTradeReq.price < settings.maxTradeAllowed &&
      (newTradeReq.tradeType !== 'carbonMarket' || +newTradeReq.price > 0)
    ) {
      const newTradeReqSubmission: INewTradeReq = {
        ...newTradeReq,
        startRoundID: roundID,
        endRoundID: roundID,
        price: Number(newTradeReq.price)
      };

      socket.emit('newTradeRequest', newTradeReqSubmission, (error: IErrorProps['error'], userWarning: string, data: 'complete') => {
        if (error) {
          setError({
            message: `Failed submitting trade request`,
            error
          });
        } else if (userWarning) {
          message.error(userWarning);
        } else if (data === 'complete') {
          notification.success({
            message: isActive
              ? 'Offer updated, ask accepting team to click refresh'
              : 'Token activated with offer, you can update the offer by re-activating',
            duration: 10
          });
          socket.emit('eventLog', {
            type: 'assets',
            log: `Token activated by ${teams.find(team => team.teamID === user.teamID)?.label} on ${newTradeReqSubmission.tradeType}, market: ${
              newTradeReqSubmission.marketID
            }, ${newTradeReqSubmission.dealType}, ${newTradeReqSubmission.volume}, ${settings.currency}${newTradeReqSubmission.price}k `
          });
        }
      });
    } else {
      message.error(
        `Trade request price must between ${
          newTradeReq.tradeType === 'carbonMarket'
            ? `0 and ${settings.maxTradeAllowed}`
            : `${settings.minTradeAllowed} and ${settings.maxTradeAllowed} and not equal to 0`
        }`
      );
    }
  };

  const market = markets.find(m => m.marketID === newTradeReq.marketID);
  return (
    <>
      <div className={styles.offerDetails}>
        <div>
          <p className={styles.infoLabel}>Deal Type</p>
          <p className={styles.infoText}>Offer to sell</p>
        </div>
        <div>
          <p className={styles.infoLabel}>Market</p>
          <p className={styles.infoText}>{market?.label}</p>
        </div>
        <div>
          <p className={styles.infoLabel}>Volume</p>
          <p className={styles.infoText}>
            {newTradeReq.volume} {market?.volumeUnits}
          </p>
        </div>
        <div>
          <p className={styles.infoLabel}>Enter a price</p>
          <Input
            name='price'
            autoComplete='off'
            inputMode='numeric'
            style={{ width: '175px', fontSize: '20px' }}
            value={newTradeReq.price}
            prefix={settings.currency}
            suffix={market?.priceUnits}
            type='text'
            pattern='^[+-]?[0-9]*'
            onChange={event =>
              event.target.validity.valid && (isInteger(Number(event.target.value)) || event.target.value === '-')
                ? setNewTradeReq({
                    ...newTradeReq,
                    price: event.target.value
                  })
                : setNewTradeReq({ ...newTradeReq, price: '' })
            }
          />
        </div>
      </div>
      <button className={classNames([styles.acceptButton, styles.button])} onClick={submitTrade}>
        {isActive ? 'Update Offer' : 'Activate Token'}
        {'  '}
        <LoginOutlined style={{ color: 'white', marginRight: '2px', cursor: 'pointer' }} />
      </button>
      <Text
        style={{ color: '#b7b7b7', marginTop: '10px' }}
        onClick={() => {
          setCode(null);
          setScannedBilateral(undefined);
        }}>
        Scan another token
      </Text>
    </>
  );
};
