import classNames from 'classnames';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Typography, message } from 'antd';
import { Timer, setupDataContext, socket } from '@powertrader/core';
import { useContext, useState } from 'react';
import styles from './BilateralScreen.module.css';
import moment from 'moment';
import { BilateralTrade } from '@powertrader/schema';

const { Text } = Typography;

const getNewEndTime = (id: number) => {
  const newEndTime = moment(new Date()).add(60, 'seconds').toString();
  window.sessionStorage.setItem(`bilateralEndTime-${id}`, newEndTime);
  return moment(new Date()).add(60, 'seconds').toString();
};
const getEndTime = (id: number) => {
  const storageEndTime = window.sessionStorage.getItem(`bilateralEndTime-${id}`);
  if (storageEndTime) {
    return storageEndTime;
  }
  return getNewEndTime(id);
};
export const ValidToken = ({
  setCode,
  rawBilateral,
  scannedBilateral,
  setScannedBilateral
}: {
  setCode: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  rawBilateral?: BilateralTrade;
  scannedBilateral: BilateralTrade;
  setScannedBilateral: React.Dispatch<React.SetStateAction<BilateralTrade | undefined>>;
}) => {
  const [endTime, setEndTime] = useState<string>(getEndTime(scannedBilateral.id));
  const { user, teams, settings } = useContext(setupDataContext);

  const refreshOffer = () => {
    setScannedBilateral(rawBilateral);
    if (rawBilateral && rawBilateral?.price !== scannedBilateral.price) {
      message.success('Offer refreshed - Clock Reset');
      setEndTime(getNewEndTime(rawBilateral.id));
    } else {
      message.success('No offer updates');
    }
  };
  const currentTeam = teams.find(team => team.teamID === user.teamID);

  const handleAcceptBilateralTrade = () => {
    socket.emit('acceptBilateralTrade', scannedBilateral?.id, currentTeam?.teamID, scannedBilateral.price);
    setCode(null);
    setScannedBilateral(undefined);
  };

  const handleRejectBilateralTrade = () => {
    socket.emit('rejectBilateralTrade', scannedBilateral?.id, currentTeam?.teamID);
    message.error('Bilateral Rejected');
    setCode(null);
    setScannedBilateral(undefined);
  };

  return (
    <>
      {rawBilateral?.price !== scannedBilateral.price && (
        <>
          <Button ghost shape='round' onClick={refreshOffer}>
            Incoming Offer {settings.currency} {rawBilateral?.price} {rawBilateral?.market.priceUnits}
          </Button>
          <Text style={{ color: 'white' }}>Click to load new offer</Text>
        </>
      )}
      <div className={styles.offerDetails}>
        <div className={styles.teamLabelGroup}>
          <p className={styles.infoLabel}>Offering Team</p>

          <h2 className={styles.teamLabel} style={{ width: 'fit-content', backgroundColor: scannedBilateral?.offeringTeam.color }}>
            {scannedBilateral?.offeringTeam.label}
          </h2>
        </div>
        <div>
          <p className={styles.infoLabel}>Market</p>
          <p className={styles.infoText}>{scannedBilateral.market?.label}</p>
        </div>
        <div>
          <p className={styles.infoLabel}>Volume</p>
          <p className={styles.infoText}>
            {scannedBilateral?.volume} {scannedBilateral.market.volumeUnits}
          </p>
        </div>
        <div>
          <p className={styles.infoLabel}>Price</p>
          <p className={styles.infoText}>
            {settings.currency} {scannedBilateral?.price} {scannedBilateral.market.priceUnits}
          </p>
        </div>
      </div>
      <div>
        <p className={styles.infoLabel}>Offer auto rejects in</p> <Timer endTime={endTime} onTimeEnded={() => handleRejectBilateralTrade()} />
      </div>
      <button className={classNames([styles.acceptButton, styles.button])} onClick={() => handleAcceptBilateralTrade()}>
        Accept <CheckCircleOutlined style={{ color: 'white', marginRight: '2px', cursor: 'pointer' }} />
      </button>
      <button className={classNames([styles.rejectButton, styles.button])} onClick={() => handleRejectBilateralTrade()}>
        Reject <CloseCircleOutlined style={{ color: 'white', marginLeft: '2px', cursor: 'pointer' }} />
      </button>

      <Text
        style={{ color: '#b7b7b7', margin: '10px' }}
        onClick={() => {
          setCode(null);
          setScannedBilateral(undefined);
        }}>
        Scan another token
      </Text>
    </>
  );
};
