import { Dispatch, SetStateAction, memo, useContext, useEffect, useState } from 'react';

import {
  Dashboard,
  HydrogenValuesProvider,
  PortfolioSelection,
  setErrorContext,
  setupDataContext,
  socket,
  teamAssetsContext
} from '@powertrader/core';
import type { IAdditionalInformationDisplayProps, IAsset, IAssetListDisplayProps, IUpdateAssetLoadSocket } from '@powertrader/schema';
import { cloneDeep } from 'lodash';
import { AssetList } from './AssetList';
import { CSSingleHeading } from './CentreStage/CSSingleHeading';
import { CenterStage } from './CentreStage/CentreStage';
import styles from './GamePlayArea.module.css';

interface IGamePlayArea {
  setTeamAssets: Dispatch<SetStateAction<IAsset[]>>;
}
const GamePlayArea = memo(({ setTeamAssets }: IGamePlayArea) => {
  const setError = useContext(setErrorContext);
  const teamAssets = useContext(teamAssetsContext);
  const { user, settings } = useContext(setupDataContext);

  const [additionalInformationDisplay, setAdditionalInformationDisplay] = useState<IAdditionalInformationDisplayProps['value']>();
  const [assetListDisplay, setAssetListDisplay] = useState<IAssetListDisplayProps['value']>('electricity');

  useEffect(() => {
    setAdditionalInformationDisplay(undefined);
  }, [settings.currentGamePhase]);

  useEffect(() => {
    socket.on('updateAssetLoad', (data: IUpdateAssetLoadSocket) => {
      if (data !== undefined && data.teamID === user.teamID) {
        // console.log('data', data)
        const index = teamAssets.findIndex(asset => asset.assetID === data.assetID);
        const newTeamAssets = cloneDeep(teamAssets);
        let found = false;
        newTeamAssets[index].load = teamAssets[index].load.map(load => {
          if (load.marketID === data.marketID && load.roundID === data.roundID) {
            found = true;

            return { ...load, load: data.option };
          }
          return load;
        });
        if (!found) {
          newTeamAssets[index].load.push({
            assetID: data.assetID,
            marketID: data.marketID,
            roundID: data.roundID,
            load: data.option
          });
        }

        setTeamAssets(newTeamAssets);
      } else {
        setError({
          message: 'Team sent invalid data through socket updateAssetLoad'
        });
      }
    });

    return () => {
      socket.off('updateAssetLoad');
    };
  }, [setError, setTeamAssets, teamAssets, user.teamID]);

  return (
    <HydrogenValuesProvider>
      <div data-testid='game-play-area' className={styles.gamePlayAreaContainer}>
        {settings.currentGamePhase === 'setup' ? (
          <div className={styles.setupScreen}>
            {' '}
            <CSSingleHeading text='You are logged in - Welcome to Power Trader' logo />
          </div>
        ) : settings.currentGamePhase === 'portfolio' ? (
          <PortfolioSelection />
        ) : (
          <>
            <div className={styles.dashboard} style={{ borderColor: user.color }}>
              <Dashboard teamID={user.teamID} setAdditionalInformationDisplay={setAdditionalInformationDisplay} />
            </div>
            <div className={styles.currentTab}>
              <AssetList
                assets={
                  assetListDisplay === 'electricity'
                    ? teamAssets.filter(asset => asset.electricityLoadType === 'generator')
                    : teamAssets.filter(asset => asset.hydrogenLoadType === 'producer')
                }
                label={assetListDisplay === 'electricity' ? 'Generation' : 'H₂ Production'}
                className={styles.generation}
                setAdditionalInformationDisplay={settings.allowUpgrades ? setAdditionalInformationDisplay : undefined}
                setAssetListDisplay={settings.allowHydrogenTrading ? setAssetListDisplay : undefined}
              />
              <div className={styles.centerWrapper}>
                <CenterStage
                  additionalInformationDisplay={additionalInformationDisplay}
                  setAdditionalInformationDisplay={setAdditionalInformationDisplay}
                />
              </div>
              <AssetList
                assets={
                  assetListDisplay === 'electricity'
                    ? teamAssets.filter(asset => asset.electricityLoadType === 'customer')
                    : teamAssets.filter(asset => asset.hydrogenLoadType === 'consumer')
                }
                label={assetListDisplay === 'electricity' ? 'Customers' : 'H₂ Consumers'}
                className={styles.customers}
                setAdditionalInformationDisplay={settings.allowUpgrades ? setAdditionalInformationDisplay : undefined}
                setAssetListDisplay={settings.allowHydrogenTrading ? setAssetListDisplay : undefined}
              />
            </div>
          </>
        )}
      </div>
    </HydrogenValuesProvider>
  );
});

export default GamePlayArea;
