import { Asset, gameProgressContext, setErrorContext, setupDataContext, socket } from '@powertrader/core';
import { IAdditionalInformationDisplayProps, IAsset, IErrorProps, ITeamStarpline, IUpdateTeamStrategyData } from '@powertrader/schema';
import { useContext, useEffect, useState } from 'react';
import styles from './TeamStats.module.css';

interface ITeamStats {
  setAdditionalInformationDisplay: IAdditionalInformationDisplayProps['setState'];
}

export const TeamStats = ({ setAdditionalInformationDisplay }: ITeamStats) => {
  const [allTeamAssets, setAllTeamAssets] = useState<IAsset[]>([]);
  const [teamStarplines, setTeamStraplines] = useState<ITeamStarpline[]>([]);

  const { teams, user } = useContext(setupDataContext);
  const { roundID } = useContext(gameProgressContext);
  const setError = useContext(setErrorContext);
  useEffect(() => {
    socket.emit('requestTeamAssets', (error: IErrorProps['error'], data?: IAsset[]) => {
      if (error) {
        setError({ error, message: 'Cannot get all team assets for team stats page' });
        return null;
      }
      if (data) {
        setAllTeamAssets(data);
      }
    });

    socket.emit('requestTeamStraplines', roundID, (error: IErrorProps['error'], data?: ITeamStarpline[]) => {
      if (error) {
        setError({ error, message: 'Cannot get all team straplines for team stats page' });
        return null;
      }

      if (data) {
        setTeamStraplines(data);
      }
    });

    socket.on('teamStatsPageHotUpdate', (data: IUpdateTeamStrategyData) => {
      setTeamStraplines(prevState => {
        const newState = [...prevState];

        const index = newState.findIndex(teamStarpline => teamStarpline.teamID === data.teamID);
        if (index !== -1) {
          newState[index].strapline = data.strapline;
          return newState;
        }
        return prevState;
      });
    });
  }, [roundID, setError]);

  return (
    <div className={styles.container}>
      <h2 className={styles.sectionTitle}>Team Portfolios</h2>
      <h3 className={styles.close} onClick={() => setAdditionalInformationDisplay(undefined)}>
        Close
      </h3>
      {!allTeamAssets.length ? null : (
        <div className={styles.teamStatsContainer}>
          {teams.map(team => {
            if (team.type !== 'PLAYER' || team.teamID === user.teamID) return null;

            const teamAssets = allTeamAssets?.filter(asset => asset.teamID === team.teamID);
            const strapline: string =
              teamStarplines.find(teamStrapline => teamStrapline.teamID === team.teamID)?.strapline || 'No Strapline Submitted';
            return (
              <div key={team.teamID} style={{ borderColor: team.color }} className={styles.teamStatRow}>
                <h2 style={{ color: team.color }}>Team {team.label}</h2>
                <h4>{strapline}</h4>
                <div className={styles.teamAssetRow}>
                  {teamAssets?.map(asset => (
                    <Asset asset={asset} key={asset.assetID} demo />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
